import React, { useEffect, useState } from "react";
import NcImage from "components/NcImage/NcImage";

import SectionVideos from "./SectionVideos";
import SectionHero2 from "./SectionHero2";
import becomAuthorImg from "images/BecomeAnAuthorImg.png";
import { DEMO_POSTS, DEMO_POSTS_VIDEO } from "data/posts";
import { DEMO_AUTHORS } from "data/authors";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionAds from "./SectionAds";
import SectionSliderNewAuthors from "components/SectionSliderNewAthors/SectionSliderNewAuthors";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import { TaxonomyType } from "data/types";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionGridPosts from "./SectionGridPosts";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionTrending from "./SectionTrending";
import SectionMagazine6 from "./SectionMagazine6";
import ButtonPrimary from "components/Button/ButtonPrimary";
import ButtonSecondary from "components/Button/ButtonSecondary";

import header_layer_black from "images/header_layer_black.jpg"
import ReactPlayer from "react-player";
import NcPlayIcon from "components/NcPlayIcon/NcPlayIcon";
import background_1 from "images/background_1.png"
import background_2 from "images/background_2.png"

const PageHomepageFr: React.FC = () => {
  const [isPlay, setIsPlay] = useState(false);


  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-astronomy theme-blue-coolGrey";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  return (
    <div className="nc-PageHomepageFr relative">
      <Helmet>
        <title>LA DECLARATION DE BRUXELLES</title>
      </Helmet>
      {/* ======== BG GLASS ======== */}
      <div className={`absolute inset-x-0 top-0 h-200 w-25 pl-10 py-32 mt-90 flex flex-col overflow-hidden z-0`}>
        <div className={`nc-NcImage absolute inset-0  overflow-hidden`}
          data-nc-id="NcImage">
          <img src={background_1}
            className="object-cover opacity-50" />
        </div>
      </div>
      <div className={`absolute inset-r-0 top-0 h-200 right-0 w-25 pl-10 py-32 mt-180 flex flex-col overflow-hidden z-0`}>
        <div className={`nc-NcImage absolute inset-0 right-0  overflow-hidden`}
          data-nc-id="NcImage">
          <img src={background_2}
            className="object-cover opacity-50" />
        </div>
      </div>
      <div className={`absolute inset-x-0 top-0 h-200 w-25 pl-10 py-32 mt-270 flex flex-col overflow-hidden z-0`}>
        <div className={`nc-NcImage absolute inset-0  overflow-hidden`}
          data-nc-id="NcImage">
          <img src={background_1}
            className="object-cover opacity-50" />
        </div>
      </div>
      <div className={`absolute inset-r-0 top-0 h-200 right-0 w-25 pl-10 py-32 mt-350 flex flex-col overflow-hidden z-0`}>
        <div className={`nc-NcImage absolute inset-0 right-0  overflow-hidden`}
          data-nc-id="NcImage">
          <img src={background_2}
            className="object-cover opacity-50" />
        </div>
      </div>
      <div className={`absolute inset-x-0 bottom-0 h-100 w-25 pl-10 py-32  flex flex-col z-0`}>
        <div className={`nc-NcImage absolute inset-0`}
          data-nc-id="NcImage">
          <img src={background_1}
            className="object-cover opacity-50" />
        </div>
      </div>
      {/* ======== ALL SECTIONS ======== */}
      <div className="relative">
        <div className="relative pb-20 md:py-16 lg:py-32 bg-black">
          <div className="flex w-full mb-10  md:absolute md:right-0 md:top-0 md:bottom-0 md:mb-0">
            <div className="hidden md:block absolute top-0 left-0 bottom-0  from-black bg-gradient-to-r"></div>
            <img className="w-full h-full object-cover"
              src={header_layer_black}
              alt="" />
          </div>
          <div className="container relative z-10 text-neutral-100">
            <div className="max-w-4xl">
              <h1 className="font-bold text-4xl md:text-5xl xl:text-6xl mt-0 md:!leading-[110%] ">
              FAIRE LE CHOIX DE LA DÉMOCRATIE

              </h1>
              <p className="mt-7 text-base lg:text-xl text-neutral-300 ">
              Déclaration de Bruxelles : protégeons la sécurité des journalistes et la liberté des médias</p>
              <p className="mt-7 text-base lg:text-xl text-neutral-300 ">La violence et le harcèlement en ligne contre les journalistes et les professionnels des médias sont à la hausse partout dans le monde, et ces attaques ciblent de façon disproportionnée les femmes et les membres de groupes sous-représentés.</p>
              <p className="mt-7 text-base lg:text-xl text-neutral-300 ">Cette tendance inquiétante en amène plusieurs à s’autocensurer ou à déserter la profession, limitant la capacité des médias d’information à relayer des points de vue diversifiés.</p>
              <p className="mt-7 text-base lg:text-xl text-neutral-300 ">Cela affaiblit un des principaux fondements de nos sociétés démocratiques : la liberté de la presse. </p>
              <p className="mt-7 text-base lg:text-xl text-neutral-300 ">Nous devons absolument inverser cette tendance. C’est pourquoi les médias de service public et leurs alliés unissent leurs forces afin de protéger les journalistes et les professionnels des médias.  </p>
              <div className="flex space-x-4 mt-11 text-center">
                <ButtonPrimary href={`/fr/declaration`}>Consultez la Déclaration de Bruxelles</ButtonPrimary>
                <ButtonSecondary href={`/fr/signatory`}>Signataires</ButtonSecondary>
              </div>
            </div>
          </div>

        </div>
         {/*<div className="container relative">

          <div className="videoMargin group aspect-w-16 aspect-h-16 sm:aspect-h-9 bg-neutral-800 rounded-3xl overflow-hidden border-0 border-transparent dark:border-neutral-900 sm:rounded-[50px] sm:border-[10px]">
            {
              isPlay ? (
                <ReactPlayer url={`https://player.vimeo.com/external/496576850.hd.mp4?s=17b8e391ef0dd67993cd6805e33a327b785dc0e4&profile_id=175`}
                  playing
                  width="100%"
                  height="100%"
                  controls
                  light={``}
                  playIcon={<NcPlayIcon />} />
              ) : (
                <>
                  <div onClick={
                    () => setIsPlay(true)
                  }
                    className="cursor-pointer absolute inset-0 flex items-center justify-center z-10">
                    <NcPlayIcon />
                  </div>
                  <NcImage containerClassName="absolute inset-0 " className="object-cover w-full h-full transform transition-transform group-hover:scale-105 duration-300 nc-will-change-transform"
                    src={`video.thumbnail`}
                    title={'`video.title`'}
                    alt={`video.title`} />
                </>
              )
            } </div>
        </div> */}

        <div className="container  relative videoMargin mb-16">
          {/* === SECTION 1 === */}
          <div className=" py-16">
            <BackgroundSection />

            <div className={`nc-SectionHomeContent`}>

              <div className={`nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between`}>
                <div className="text-left w-full  mx-auto ">
                  <h1 className={`text-4xl md:text-5xl font-semibold`}>
                    Contexte
                  </h1>
                  <h2 className={`text-xl md:text-2xl font-semibold mt-8`}>
                    ATTAQUES CONTRE LES JOURNALISTES ET LA LIBERTÉ DES MÉDIAS : UNE MENACE QUI PREND DE L’AMPLEUR
                  </h2>
                </div>
              </div>
              <div className={`nc-Section-Text mt-8 relative flex flex-col sm:flex-row sm:items-end justify-between`}>
                <div className="text-justify w-full mx-auto ">
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>La violence physique et le harcèlement en ligne contre les journalistes, les équipes techniques et les professionnels des médias sont à la hausse partout dans le monde, et cette tendance s’accélère dans la foulée de la pandémie de COVID-19.</p>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey text-80`}>Le nombre d’alertes relatives à la liberté des médias reçues par la <a href="https://www.coe.int/fr/web/media-freedom/all-alerts?p_p_id=sojdashboard_WAR_coesojportlet&p_p_lifecycle=0&p_p_col_id=column-4&p_p_col_count=3&_sojdashboard_WAR_coesojportlet_selectedStringFilters=special.killed%2Cyear.2021&_sojdashboard_WAR_coesojportlet_selectedCategories=" target="_blank" className="text-underline">Plateforme pour la sécurité des journalistes</a><span className="font-bold"> a augmenté de près de <span className="text-highlight">40 %</span></span><span className="text-highlight"> entre 2019 et 2020, alors que </span><span className="font-bold text-highlight">les signalements d’agression physique, de harcèlement et d’intimidation ont atteint un niveau record¹.</span>
                  </p>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>En parallèle, les lois et règlements d’exception adoptés par les
gouvernements pour lutter contre la pandémie ont engendré « des
ingérences arbitraires dans le travail légitime des journalistes et
des organisations de presse », qui « ont porté gravement atteinte
aux droits issus de l’article 10 » de la Convention européenne des
droits de l’homme<sup>2</sup>. L’UNESCO observe par ailleurs une « intolérance
croissante à l’égard des reportages, favorisée par un climat de
rhétorique anti-presse endémique, notamment de la part des dirigeants politiques<sup>3</sup> ». Cette intolérance a pris la forme, entre
autres, de violences et d’insultes à l’endroit des journalistes couvrant
les manifestations liées à la COVID-19 en  <a href="https://ipi.media/disturbing-pattern-of-violence-and-harassment-at-covid-related-protests-across-europe/" target="_blank" className="text-underline">Europe</a> et celles du
mouvement Black Lives Matter aux  <a href="https://www.theguardian.com/media/2020/jun/05/im-getting-shot-attacks-on-journalists-surge-in-us-protests" target="_blank" className="text-underline">États-Unis</a>.                  </p>


                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>Les attaques meurtrières visant des journalistes ne se limitent
plus aujourd’hui aux pays déchirés par la guerre. En juillet 2021, le
journaliste d’enquête néerlandais <a href="https://www.francetvinfo.fr/monde/pays-bas/pays-bas-mort-du-journaliste-blesse-par-balles-a-amsterdam_4703045.html" target="_blank" className="text-underline">Peter R. de Vries</a> est mort frappé
par balle en plein centre d’Amsterdam, tandis que le caméraman
géorgien <a href="https://www.lemonde.fr/international/article/2021/07/11/georgie-un-journaliste-meurt-apres-une-agression-par-des-militants-d-extreme-droite_6087912_3210.html" target="_blank" className="text-underline">Aleksandre Lashkarava</a> a été retrouvé sans vie à son domicile
après avoir été roué de coups par des manifestants anti-LGBTQ+
pendant une marche de la Fierté à Tbilissi. En avril, c’est le reporter
grec <a href="https://www.lemonde.fr/international/article/2021/04/09/george-karaivaz-journaliste-grec-specialise-dans-les-affaires-criminelles-abattu-a-athenes_6076237_3210.html" target="_blank" className="text-underline">Giorgos Karaivaz</a>, spécialisé dans les affaires criminelles, qui
succombait à l’attaque de tireurs à moto près de son domicile à
Athènes. Ces agressions mortelles révèlent une tendance de fond.</p>

                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey text-80`}>L’UNESCO établit que <span className="font-bold text-highlight">61 % des meurtres de journalistes commis dans le monde en 2019 se sont produits dans des pays sans conflit armé</span>, comparativement à 50 % en 2016⁴.</p>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>La Public Media Alliance a souligné que l’attaque par balle contre
M. De Vries s’est produite dans un contexte où les « attaques
contre les journalistes sont en hausse dans les démocraties bien
établies d’Europe — même dans celles qui jouissent d’une grande
liberté de la presse », citant à l’appui des cas précis de violences
physiques et de mesures législatives menaçantes aux Pays-Bas,
en Allemagne, en Italie et au Royaume-Uni⁵.</p>

                  <h2 className={`text-xl md:text-2xl font-semibold mt-8 text-uppercase`}>
                    Les femmes et les groupes sous-représentés pris pour cibles
                  </h2>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>Les attaques contre les journalistes ciblent de façon disproportionnée
les femmes et les membres de groupes sous-représentés, comme en
témoigne une série de rapports publiés par l'<a href="https://fr.unesco.org/themes/safety-journalists/dgreport" target="_blank" className="text-underline">UNESCO</a>, le  <a href="https://rm.coe.int/rapport-annuel-fra-liberte-des-medias-en-europe-web/1680a2489f" target="_blank" className="text-underline">Conseil de l'Europe</a>,
                    l'<a href="https://www.iwmf.org/wp-content/uploads/2018/09/Attacks-and-Harassment.pdf" target="_blank" className="text-underline">International Women’s Media Foundation</a>,  <a href="https://rsf.org/sites/default/files/le_journalisme_face_au_sexisme.pdf" target="_blank" className="text-underline">Reporters sans
frontières</a> et  <a href="https://decoders.amnesty.org/projects/troll-patrol/findings#what_did_we_find_container" target="_blank" className="text-underline">Amnesty International</a>.</p>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>Selon l’UNESCO, les femmes sont plus que leurs homologues
masculins « soumises à l’intimidation, aux menaces et au harcèlement de nature sexuelle ou sexiste, ainsi qu’à des formes
particulières d’attaques en ligne, telles que le cyberharcèlement, le
doxxing et le trolling<sup>6</sup>». En 2020, l’organisation a mené une <a href="https://unesdoc.unesco.org/ark:/48223/pf0000375136_fre" target="_blank" className="text-underline">enquête</a> auprès de <span className="text-highlight">714 femmes journalistes issues de 15 pays, révélant que
73 % d’entre elles avaient subi des violences en ligne; 25 % des
femmes interrogées s’étaient vues menacées de violences physiques
et 18 % avaient reçu des menaces de violences sexuelles.</span></p>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey text-80`}><span className="font-bold">Les attaques en ligne se traduisent souvent par des
agressions sur le terrain : </span> 20 % des femmes interrogées
déclaraient avoir été attaquées ou agressées physiquement en
relation avec la violence en ligne dont elles avaient été victimes⁷.</p>

                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>
                  « Être femme, journaliste et issue d’une minorité représente un
risque accru », observait Reporters sans frontières dans un récent <a href="https://rsf.org/sites/default/files/le_journalisme_face_au_sexisme.pdf" target="_blank" className="text-underline">rapport</a> publié en mars 2021. L’organisation constate que les
violences sexistes et sexuelles se doublent d’autres types d’injures,
y compris de nature raciste, lesbophobe ou transphobe, ou encore
basée sur la religion<sup>8</sup>. Des données compilées par <a href="https://decoders.amnesty.org/projects/troll-patrol/findings#what_did_we_find_container" target="_blank" className="text-underline">Amnesty
International</a> montrent par ailleurs que les femmes journalistes
racisées constituent des cibles de trollage particulièrement prisées.</p>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey `}><span className="text-highlight">Les femmes politiques et journalistes de couleur sont <span className="font-bold">34 %
plus à risque</span> que les femmes blanches d’être <span className="font-bold">mentionnées
dans des tweets injurieux ou problématiques</span>, et ce chiffre est de <span className="font-bold">84% pour les femmes noires</span>.⁹</span></p>

                  <h2 className={`text-xl md:text-2xl font-semibold mt-8 text-uppercase`}>Le pluralisme et la démocratie en jeu</h2>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>Les réponses des autorités à la pandémie de COVID-19 « ont été à
l’origine de graves atteintes à la liberté de la presse » et « ont limité
les possibilités pour les journalistes de couvrir librement la situation
en matière de santé publique<sup>10</sup> ». De telles restrictions « résultent
d’une volonté délibérée ou d’un mépris pour le rôle crucial que
jouent les médias en temps de crise<sup>11</sup> ». S’il est urgent de lever ces
restrictions, cela ne suffira pas à enrayer la perte de liberté des
médias pour renverser la situation. Les impacts de la violence
physique et du harcèlement en ligne sont eux aussi considérables,
bien que plus insidieux.</p>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey text-80`}>
                  Au terme de son enquête menée dans 12 pays, Reporters sans frontières établit que <span className="font-bold">48 % des femmes journalistes</span> s’autocensurent déjà pour éviter de s’exposer à la violence, précisant par ailleurs que <span className="font-bold">21%</span> des femmes journalistes interrogées <span>ont démissionné ou envisagent de ne pas renouveler</span> leur contrat¹².
                  </p>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>Les menaces qui pèsent sur la sécurité des journalistes érodent
rapidement la liberté des médias. Que les femmes et les membres de
groupes sous-représentés soient plus exposés à ces menaces limite
en outre la capacité des médias d’information à relayer des points de
vue diversifiés, affaiblissant ainsi le pluralisme et la démocratie.</p>


                </div>
              </div>
            </div>
            <hr className={`content-spacer`}></hr>
            <div className={`nc-SectionHomeContent `}>
              <div className={`nc-Section-Text mt-16 relative flex flex-col sm:flex-row sm:items-end justify-between`}>
                <div className="text-justify w-full mx-auto ">
                  <h1 className={`text-4xl md:text-5xl font-semibold`}>Objectifs de la Déclaration de Bruxelles</h1>
                  <h2 className={`text-xl md:text-2xl font-semibold mt-8 mb-8 text-uppercase`}>Un plan d’action collectif qui s’inspire des grands
principes des médias publics</h2>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>La Déclaration de Bruxelles est une initiative conjointe du diffuseur
public canadien, CBC/Radio-Canada, et des diffuseurs publics de
la Belgique francophone et flamande, RTBF et VRT. La Déclaration
a été présentée officiellement à la <a href="https://pbibrussels2021.be/" target="_blank" className="text-underline">conférence PBI (Public
Broadcasters International)</a> de Bruxelles, qui s’est tenue en ligne le
30 septembre 2021. Organisée autour du thème « Un choix pour la
démocratie », la rencontre a mis de l’avant le rôle des médias publics
en tant que « pilier de la démocratie et de l’État de droit ».</p>

                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>
                  L’objectif de la Déclaration de Bruxelles est d’exprimer les
préoccupations communes des signataires face aux menaces
accrues qui pèsent sur la sécurité des journalistes et la liberté de la
presse. La Déclaration propose un plan d’action collectif pour aider à
atténuer le problème. <span className="font-bold">Cinq (5) grands principes</span> ont été établis pour
orienter les démarches des signataires :</p>
                  <ol className={`text-xl md:text-xl mb-8 font-semibold text-grey numerotedList`}>
                    <li>Améliorer la sécurité des journalistes, des équipes techniques et des professionnels des médias</li>
                    <li>Défendre l’indépendance des médias de service public</li>
                    <li>Entretenir un débat démocratique éclairé et courtois</li>
                    <li>Soutenir un écosystème de l’information dynamique et la diversité des sources</li>
                    <li>Promouvoir la diversité, l’équité et l’inclusion dans nos organisations comme dans les sociétés que nous servons</li>
                  </ol>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>La Déclaration détaille les moyens par lesquels les médias de service
public pourront mettre en pratique ces principes. Les médias
signataires s’engagent parallèlement à répertorier leurs meilleures
pratiques et à les faire connaître à leurs pairs dans le but d’en tirer les
plus grands bienfaits.</p>
                  <h2 className={`text-xl md:text-2xl font-semibold mt-8 mb-8 text-uppercase`}>
                  Favoriser la sensibilisation, la mobilisation et l’action à
l’échelle mondiale
                  </h2>

                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>Les médias publics ne sont qu’un élément de l’équation pour mettre
en place une solution. S’ils peuvent contribuer à atténuer certains
des pires impacts de la crise que traversent nos démocraties, ils ne
peuvent agir seuls.</p>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>C’est pourquoi la Déclaration de Bruxelles poursuit également
l’objectif de sensibiliser le public international à l’interdépendance
entre la sécurité des journalistes, la liberté des médias et la
démocratie. Pour ce faire, les signataires dénonceront les élus et
autres acteurs qui alimentent directement ou indirectement la
méfiance à l’endroit des médias d’information, qui attaquent les
journalistes et les professionnels des médias ou qui, par leurs actions,
minent l’indépendance des médias publics. Les signataires enjoignent aussi à des tiers partis, comme les entreprises de médias sociaux et les autorités réglementaires, de répondre à la menace posée par la haine en ligne qui cible précisément les journalistes sur les plateformes externes.</p>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>L’adoption de la Déclaration de Bruxelles survient à un moment clé à
l’échelle mondiale, et tout particulièrement dans l’Union européenne.
Le 15 septembre dernier, la Commission européenne a présenté
des recommandations concernant la sécurité des journalistes et
des autres professionnels de l’information en demandant aux États
membres « d’agir résolument pour faire de l’Union un lieu plus sûr
pour les journalistes<sup>13</sup> ». Cette nouvelle série de recommandations
prévoit notamment des mesures axées « sur les événements de
protestation et les manifestations, sur la sécurité en ligne et les
moyens d’action numériques, et sur les femmes journalistes et
les journalistes appartenant à des groupes minoritaires.<sup>14</sup> » La
Commission prévoit d’ailleurs présenter une législation européenne
sur la liberté des médias en 2022.</p>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>
                  Les signataires de la Déclaration de Bruxelles veulent saisir cette
occasion pour amplifier la mobilisation mondiale et encourager
la prise d’actions de la part de partenaires clés, comme les
gouvernements et les autorités publiques, les organisations
multilatérales, les ONG, les médias d’information privés et
communautaires et les plateformes de médias sociaux. Le but est
de rallier toujours plus d’adhérents aux principes de la Déclaration
de Bruxelles, qui devraient s’appliquer non seulement aux médias
publics, mais à l’ensemble de la société civile.
                  </p>
                </div>
              </div>
            </div>
            <hr className={`content-half-spacer mt-8 mb-16`}></hr>
            <div className={`nc-SectionHomeContent `}>
              <div className={`nc-Section-Text mt-16 relative flex flex-col sm:flex-row sm:items-end justify-between`}>
                <div className="text-justify w-full mx-auto ">


                  <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">1.</sup>Conseil de l’Europe, <a href="https://rm.coe.int/rapport-annuel-fra-liberte-des-medias-en-europe-web/1680a2489f" target="_blank" className="text-underline">Liberté des médias en Europe</a> : des actions concrètes s’imposent!, Rapport
annuel des organisations partenaires de la Plateforme du Conseil de l’Europe pour renforcer la
protection du journalisme et la sécurité des journalistes, avril 2021, p. 7.</p>
                  <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">2.</sup>
                    Conseil de l’Europe, p. 6.</p>
                  <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">3.</sup>
                    UNESCO, <a href="https://www.unesco.org/reports/sjdi/2020/fr/" target="_blank" className="text-underline">Les principales conclusions</a> du rapport 2020 de la Directrice générale sur la sécurité des
journalistes et le danger de l’impunité.</p>
                  <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">4.</sup>
                    UNESCO.</p>
                  <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">5.</sup>
                    Public Media Alliance, <a href="https://www.publicmediaalliance.org/threats-to-journalists-on-the-rise-across-europe/" target="_blank" className="text-underline">Threats to Journalists on the Rise Across Europe</a>, 9 Juillet, 2021.</p>
                  <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">6.</sup>
                    UNESCO.</p>
                  <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">7.</sup>
                    Julie Posetti and al., <a href="https://unesdoc.unesco.org/ark:/48223/pf0000375136_fre" target="_blank" className="text-underline">Violence en ligne à l’égard des femmes journalistes : un aperçu mondial des
incidences et impacts</a>, UNESCO, 2020.</p>
                  <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">8.</sup>
                    Reporters sans frontières, <a href="https://rsf.org/sites/default/files/le_journalisme_face_au_sexisme.pdf" target="_blank" className="text-underline">Le journalisme face au sexisme</a>, mars 2021, p. 20.</p>
                  <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">9.</sup>
                    Amnesty International, <a href="https://decoders.amnesty.org/projects/troll-patrol/findings#what_did_we_find_container" target="_blank" className="text-underline">Troll Patrol Findings</a>: Using Crowdsourcing, Data Science & Machine Learning to Measure Violence and Abuse Against
                    Women on Twitter, 2018. Voir aussi en français : <a href="https://amnistie.ca/sinformer/communiques/international/2018/international/recherches-participatives-twitter-revelent" target="_blank" className="text-underline">Des recherches participatives sur Twitter révèlent l’ampleur choquante des violences en ligne à
l’égard des femmes</a>, 2018.</p>
                  <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">10.</sup>
                    Conseil de l’Europe, <a href="https://rm.coe.int/rapport-annuel-fra-liberte-des-medias-en-europe-web/1680a2489f" target="_blank" className="text-underline">Liberté des médias en Europe : des actions concrètes s’imposent!, Rapport
annuel des organisations partenaires de la Plateforme du Conseil de l’Europe pour renforcer la
protection du journalisme et la sécurité des journalistes</a>, avril 2021, p. 10.</p>
                  <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">11.</sup>
                    Ibid., p. 6.</p>
                  <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">12.</sup>
                    Reporters sans frontières, p. 25.</p>
                    <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">13.</sup>
                    Commission européenne, <a href="https://ec.europa.eu/commission/presscorner/detail/en/ip_21_4632" target="_blank" className="text-underline"> État de l'Union: la Commission appelle les États membres à améliorer la sécurité des journalistes dans l'ensemble de l'UE </a>, 16 septembre 2021.</p>
                    <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">14.</sup>
                    Ibid.</p>
               



                </div>
              </div>
            </div>


          </div>

        </div>


        <div className="container relative mb-16 mt-16">
          {/* === SECTION 1 === */}
          <div className=" py-16">
            <BackgroundSection />

            <div className={`nc-SectionHomeContent`}>

              <div className={`nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between`}>
                <div className="text-left w-full  mx-auto ">
                  <h1 className={`text-2xl md:text-3xl font-semibold`}>
                    LIENS UTILES
                  </h1>
                  <h2 className={`text-xl md:text-2xl font-semibold mt-8 mb-4 text-grey`}>
                  Rapports de l’UNESCO :
                  </h2>
                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">
                    <a href="https://www.unesco.org/reports/sjdi/2020/fr" target="_blank">
                    Les principales conclusions du rapport de la Directrice générale sur la sécurité des journalistes et le danger de l’impunité
                    </a>
                  </p>
                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">
                    <a href="https://unesdoc.unesco.org/ark:/48223/pf0000375136_fre" target="_blank">
                    Violence en ligne à l’égard des femmes journalistes : un aperçu mondial des incidences et impacts
                    </a>
                  </p>
                  <h2 className={`text-xl md:text-2xl font-semibold mt-8 mb-4 text-grey`}>
                    Ressources du Conseil de l’Europe :
                  </h2>
                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">

                    <a href="https://rm.coe.int/rapport-annuel-fra-liberte-des-medias-en-europe-web/1680a2489f" target="_blank">
                    Liberté des médias en Europe : des actions concrètes s’imposent!
                    </a>
                  </p>
                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">

                    <a href="https://www.coe.int/fr/web/media-freedom/home" target="_blank">
                    Plateforme pour la sécurité des journalistes
                    </a>
                  </p>
                  <h2 className={`text-xl md:text-2xl font-semibold mt-8 mb-4 text-grey`}>
                  Commission européenne :
                  </h2>
                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">

                    <a href="https://ec.europa.eu/commission/presscorner/detail/fr/ip_21_4632" target="_blank">
                    Recommandations visant à renforcer
la sécurité des journalistes et autres professionnels des médias
                    </a>
                  </p>

                  <h2 className={`text-xl md:text-2xl font-semibold mt-8 mb-4 text-grey`}>
                  Rapport de Reporters sans frontières :
                  </h2>
                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">

                    <a href="https://rsf.org/sites/default/files/le_journalisme_face_au_sexisme.pdf" target="_blank">
                    Le journalisme face au sexisme
                    </a>
                  </p>
                  <h2 className={`text-xl md:text-2xl font-semibold mt-8 mb-4 text-grey`}>
                  Rapport d’Amnesty International :
                  </h2>
                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">

                    <a href="https://decoders.amnesty.org/projects/troll-patrol/findings#what_did_we_find_container" target="_blank">
                      Troll Patrol Findings
                    </a>
                  </p>
                  <h2 className={`text-xl md:text-2xl font-semibold mt-8 mb-4 text-grey`}>
                  Rapport de l’International Women’s Media Foundation :
                  </h2>
                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">

                    <a href="https://www.iwmf.org/wp-content/uploads/2018/09/Attacks-and-Harassment.pdf" target="_blank">
                      Attacks and Harassment: The Impact on Female Journalists and Their Reporting
                    </a>
                  </p>
                  <h2 className={`text-xl md:text-2xl font-semibold mt-8 mb-4 text-grey`}>
                  Rapport du Guardian et de Bellingcat :
                  </h2>
                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">

                    <a href="https://www.theguardian.com/media/2020/jun/05/im-getting-shot-attacks-on-journalists-surge-in-us-protests" target="_blank">
                      Attacks on journalists surge in US protests
                    </a>
                  </p>

                  <h2 className={`text-xl md:text-2xl font-semibold mt-8 mb-4 text-grey`}>
                  Déclarations du Groupe de travail mondial pour les médias publics :
                  </h2>
                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">
                    <a href="https://www.publicmediaalliance.org/la-securite-des-journalistes-est-en-jeu/" target="_blank">
                    La sécurité des journalistes est en jeu
                    </a>
                  </p>
                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">
                    <a href="https://www.publicmediaalliance.org/sattaquer-aux-medias-publics-cest-attaquer-la-democratie/" target="_blank">
                    S’attaquer aux médias publics, c’est attaquer la démocratie
                    </a>
                  </p>
                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">
                    <a href="https://www.publicmediaalliance.org/le-groupe-de-travail-mondial-pour-les-medias-publics/" target="_blank">
                    Mandat et autres déclarations
                    </a>
                  </p>

                  <h2 className={`text-xl md:text-2xl font-semibold mt-8 mb-4 text-grey`}>
                    <a href="https://www.publicmediaalliance.org/" target="_blank" className="text-underline">Public Media Alliance</a> :
                  </h2>
                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">
                    <a href="https://protect-eu.mimecast.com/s/XgbuCRL00u8wWkKCNEve2?domain=publicmediaalliance.org" target="_blank">
                      Threats to Journalists on the Rise Across Europe
                    </a>
                  </p>

                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">
                    <a href="https://www.publicmediaalliance.org/global-call-out-world-press-freedom-day-2021/" target="_blank">
                      Global Call Out: Information as a Public Good
                    </a>
                  </p>

                  <h2 className={`text-xl md:text-2xl font-semibold mt-8 mb-4 text-grey`}>
                    <a href="https://pbibrussels2021.be/" target="_blank" className="text-underline">PBI Brussels 2021</a>
                  </h2>


                </div>
              </div>


            </div>

          </div>


        </div>
      </div>
    </div>
  );
};

export default PageHomepageFr;
