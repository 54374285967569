import React, { useEffect } from "react";

import { Helmet } from "react-helmet";
import SectionHeroRtbf from "./SectionHeroRtbfFr";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import NcImage from "components/NcImage/NcImage";
import background_1 from "images/background_1.png"
import background_2 from "images/background_2.png"


const PageDeclarationFr: React.FC = () => {
  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-astronomy theme-blue-coolGrey";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  return (
    <div className="nc-PageHomepage overflow-hidden relative">
      <Helmet>
        <title>LA DECLARATION DE BRUXELLES</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <div className={`absolute inset-x-0 top-0 h-200 w-25 pl-10 py-32 mt-90 flex flex-col overflow-hidden z-0`}>
        <div className={`nc-NcImage absolute inset-0  overflow-hidden`} data-nc-id="NcImage">
          <img src={background_1} className="object-cover opacity-50" />
        </div>
      </div>
      <div className={`absolute inset-r-0 top-0 h-200 right-0 w-25 pl-10 py-32 mt-180 flex flex-col overflow-hidden z-0`}>
        <div className={`nc-NcImage absolute inset-0 right-0  overflow-hidden`} data-nc-id="NcImage">
          <img src={background_2} className="object-cover opacity-50" />
        </div>
      </div>
      {/* ======= START CONTAINER ============= */}
      <SectionHeroRtbf className="pb-16 mt-8 mr-8 ml-8 lg:pb-20" />
      <div className={`container relative mb-16 text-center`}>

<a href="https://forms.office.com/pages/responsepage.aspx?id=Pvs8EP09REqDzkVaLSXbBi-20EFIgblKpHxwyv1Ol4JUQUMwNE9WS1pDMFRCV0daM0E1V0Q0NUUxQS4u" target="_blank" className="text-center text-xl md:text-xl font-semibold text-blue">Soutenez la Déclaration de Bruxelles</a>
</div>
      <div className="container relative mb-16">
        {/* === SECTION 1 === */}
        <div className=" py-16">
          <BackgroundSection />
          
          <div className={`nc-SectionHomeContent`}>
            
      <div className={`nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between`}>
        <div className="text-left w-full  mx-auto ">
          <h1 className={`text-4xl md:text-5xl font-semibold`}>
          LES MÉDIAS DE SERVICE PUBLIC DÉFENDENT LA SÉCURITÉ DES JOURNALISTES, LA LIBERTÉ DES MÉDIAS ET LA DÉMOCRATIE

          </h1>
        </div>
      </div>
      <div className={`nc-Section-Text mt-16 relative flex flex-col sm:flex-row sm:items-end justify-between`}>
        <div className="text-justify w-full mx-auto ">
          <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>Considérant que la Déclaration universelle des droits de l’homme consacre, depuis 1948, le droit à la liberté d’opinion et d’expression, ce qui « implique le droit de ne pas être inquiété pour ses opinions et celui de chercher, de recevoir et de répandre, sans considérations de frontières, les informations et les idées par quelque moyen d’expression que ce soit<sup>1</sup>»;</p>
          <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>Considérant que la liberté d’expression et la liberté de la presse sont des fondements essentiels d’une société démocratique, comme le reconnaît la Cour européenne des droits de l’homme, qui énonce que sans « le pluralisme, la tolérance et l’esprit d’ouverture [...] il n’y a pas de “société démocratique”<sup>2</sup>»;</p>
          <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>Considérant que ces libertés sont menacées par la présente montée de la violence physique et du harcèlement en ligne contre les journalistes, les équipes techniques et les professionnels des médias, ciblant les femmes et les membres de groupes sous-représentés de manière disproportionnée<sup>3</sup> — une tendance favorisée par ce que l’UNESCO décrit comme « un climat de rhétorique anti-presse endémique, notamment de la part des dirigeants politiques<sup>4</sup>»;</p>
          <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>Considérant que cette tendance s’accélère dans la foulée de la pandémie de COVID-19<sup>5</sup>, suscitant une hausse de l’autocensure chez les journalistes et le départ d’un plus grand nombre de professionnels des secteurs de l’information et des médias, ce qui entraîne un affaiblissement du pluralisme et de la démocratie;</p>
          <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>En conséquence,</p>        
          <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>Toutes les organisations médiatiques soussignées énoncent les principes suivants, qu’elles ont le devoir de respecter et de promouvoir, autant sur une base individuelle qu’au moyen d’actions concertées.</p>
       
       
        </div>
      </div>
    </div>
    <hr className={`content-spacer`}></hr>
    <div className={`nc-SectionHomeContent `}>
      <div className={`nc-Section-Text mt-16 relative flex flex-col sm:flex-row sm:items-end justify-between`}>
        <div className="text-justify w-full mx-auto ">
          <h3 className={`text-2xl md:text-2xl font-semibold mb-8`}>PRINCIPES</h3>

          <h2 className={`text-6xl md:text-6xl font-semibold text-grey`}>1.</h2>
          <h3 className={`text-xl md:text-xl font-semibold text-grey`}>Améliorer la sécurité des journalistes, des équipes techniques et des professionnels des médias</h3>
          <p className={`text-xl md:text-xl mb-8 mt-8 font-medium text-grey`}>Tous les journalistes, les membres des équipes techniques et les professionnels des médias doivent être en mesure de réaliser leur travail en toute sécurité. À cette fin, les signataires s’engagent à fournir les ressources et le soutien dont leurs employés ont besoin pour se prémunir contre la violence physique et le harcèlement en ligne, tout en sensibilisant le public à l’impact de ces attaques sur la démocratie.</p>

          <h2 className={`text-6xl md:text-6xl font-semibold text-grey`}>2.</h2>
          <h3 className={`text-xl md:text-xl font-semibold text-grey`}>Défendre l’indépendance des médias de service public</h3>
          <p className={`text-xl md:text-xl mb-8 mt-8 font-medium text-grey`}>Les signataires s’engagent à dénoncer publiquement toute tentative visant à saper l’indépendance des médias de service public, que ce soit sous la forme de pressions politiques, de menaces ou de représailles financières, de harcèlement ou d’attaques envers les employés, ou de rhétorique antimédia. Ils s’engagent aussi à faciliter la compréhension de la distinction entre diffuseurs publics et diffuseurs d’État.</p>

          <h2 className={`text-6xl md:text-6xl font-semibold text-grey`}>3.</h2>
          <h3 className={`text-xl md:text-xl font-semibold text-grey`}>Entretenir un débat démocratique éclairé et courtois</h3>
          <p className={`text-xl md:text-xl mb-8 mt-8 font-medium text-grey`}>En réponse à la crise de désinformation à l’échelle mondiale, les signataires s’engagent à fournir des nouvelles et de l’information fiables, à soutenir les initiatives de vérifications des faits, à faire progresser l’éducation aux médias, et à surveiller et retirer le discours haineux de leurs plateformes. Ils s’engagent en outre à exiger des entreprises de médias sociaux et des autorités réglementaires qu’elles éliminent la haine en ligne sur les plateformes de tiers partis.</p>

          <h2 className={`text-6xl md:text-6xl font-semibold text-grey`}>4.</h2>
          <h3 className={`text-xl md:text-xl font-semibold text-grey`}>Soutenir un écosystème de l’information dynamique et la diversité des sources</h3>
          <p className={`text-xl md:text-xl mb-8 mt-8 font-medium text-grey`}>Des médias d’information diversifiés — autant publics, communautaires que privés — sont essentiels au dynamisme des écosystèmes de l’information et à la santé de toute démocratie. Par conséquent, les signataires s’engagent à collaborer avec d’autres organisations médiatiques de leur pays afin de protéger le journalisme local, notamment en échangeant sur leurs meilleures pratiques concernant la sécurité des journalistes et en s’exprimant d’une même voix sur leurs défis communs.</p>

          <h2 className={`text-6xl md:text-6xl font-semibold text-grey`}>5.</h2>
          <h3 className={`text-xl md:text-xl font-semibold text-grey`}>Promouvoir la diversité, l’équité et l’inclusion dans nos organisations comme dans les sociétés que nous servons</h3>
          <p className={`text-xl md:text-xl mb-8 mt-8 font-medium text-grey`}>Pour réaliser les idéaux de pluralisme, de tolérance et d’esprit d’ouverture qui sous-tendent les sociétés démocratiques, les signataires s’engagent à mieux représenter la diversité des populations qu’ils servent sur le plan de la composition et des perspectives, autant dans leur programmation que dans leur effectif — tout en menant des efforts pour rendre leurs milieux de travail inclusifs pour tous.</p>
        </div>
      </div>
    </div>
    <hr className={`content-half-spacer mt-8 mb-16`}></hr>
    <div className={`nc-SectionHomeContent `}>
      <div className={`nc-Section-Text mt-16 relative flex flex-col sm:flex-row sm:items-end justify-between`}>
        <div className="text-justify w-full mx-auto ">

          
        <p className={`text-l md:text-l mb-4 mt-4 text-grey`}><sup className="mr-2" >1.</sup> <a href="https://www.un.org/fr/about-us/universal-declaration-of-human-rights" target="_blank" className="text-underline">Déclaration universelle des droits de l’homme</a>, telle que proclamée le 10 décembre 1948.</p>
        <p className={`text-l md:text-l mb-4 mt-4 text-grey`}><sup className="mr-2" >2.</sup> <a href="http://globalfreedomofexpression.columbia.edu/wp-content/uploads/2021/03/HandysidevUK-FR.pdf" target="_blank" className="text-underline">Handyside v. United Kingdom</a> (7 décembre 1976), Columbia University: Global Freedom of Expression.</p>
        <p className={`text-l md:text-l mb-4 mt-4 text-grey`}><sup className="mr-2" >3.</sup> Voir les rapports de l'<a href="https://unesdoc.unesco.org/ark:/48223/pf0000375136_fre" target="_blank" className="text-underline">UNESCO</a>, de la <a href="https://www.iwmf.org/wp-content/uploads/2018/09/Attacks-and-Harassment.pdf" target="_blank" className="text-underline">International Women’s Media Foundation</a>, de <a href="https://rsf.org/sites/default/files/le_journalisme_face_au_sexisme.pdf" target="_blank" className="text-underline">Reporters sans frontières</a> et d'<a href="https://decoders.amnesty.org/projects/troll-patrol/findings#what_did_we_find_container" target="_blank" className="text-underline">Amnesty International</a>.</p>
        <p className={`text-l md:text-l mb-4 mt-4 text-grey`}><sup className="mr-2" >4.</sup> <a href="https://unescolive.wabamedia.co/fr/" target="_blank" className="text-underline">Les principales conclusions du rapport 2020 de la Directrice générale sur la sécurité des journalistes et le danger de l’impunité.</a></p>
        <p className={`text-l md:text-l mb-4 mt-4 text-grey`}><sup className="mr-2" >5.</sup> <a href="https://rm.coe.int/rapport-annuel-fra-liberte-des-medias-en-europe-web/1680a2489f" target="_blank" className="text-underline">Liberté des médias en Europe : des actions concrètes s’imposent!</a>, Rapport annuel des organisations partenaires de la Plateforme du Conseil de l’Europe pour renforcer la protection du journalisme et la sécurité des journalistes, Conseil de l’Europe, avril 2021.</p>

        </div>
      </div>
    </div>
    

        </div>

      </div>
    </div>
  );
};

export default PageDeclarationFr;
