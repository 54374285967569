import LayoutPage from "components/LayoutPage/LayoutPage";
// import React, { FC } from "react";
import React, { FC,useEffect, useRef } from 'react';
import axios from 'axios';
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";
import NcLink from "components/NcLink/NcLink";
import { Helmet } from "react-helmet";
import Cookies from 'universal-cookie';
import { NavLink, useHistory } from 'react-router-dom';

export interface PageLoginProps {
  className?: string;
}
const cookies = new Cookies();




const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const history = useHistory();

  const onFormSubmit = (e:any) => {
    e.preventDefault();
    console.log("submit",e)
    console.log("submit",email.current.value)
    console.log("submit",password.current.value)
    const credentials = {
      email :email.current.value,
      password :password.current.value,
    };


    axios.post(`https://backend.develop.rtbf.flykube.agifly.cloud/api/v1/auth/sign_in`,credentials).then(response => {
     const token = response.data.data.auth.token;

     console.log("response",response)
      cookies.set("op-token", token, {
        maxAge: 60 * 60 * 2,
        path: '/',
        sameSite: 'none',
        secure: true,
      });
      localStorage.setItem("op-token", token);
      history.push('/admin');


    }).catch(err =>{
      console.log("error",err)
    })

    // send to server with e.g. `window.fetch`
  }
  let email = useRef() as React.MutableRefObject<HTMLInputElement>;
  let password = useRef() as React.MutableRefObject<HTMLInputElement>;


  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <LayoutPage
        headingEmoji="🔑"
        heading="Login"
      >
        <div className="max-w-md mx-auto space-y-6">

          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" action="#" method="post" onSubmit={onFormSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                ref={email}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input type="password" className="mt-1" ref={password} />
            </label>
            <ButtonPrimary type="submit">Continue</ButtonPrimary>
          </form>
        </div>
      </LayoutPage>
    </div>
  );
};

export default PageLogin;
