import React, { useEffect } from "react";

import { Helmet } from "react-helmet";
import SectionHeroRtbf from "./SectionHeroRtbf";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import NcImage from "components/NcImage/NcImage";
import background_1 from "images/background_1.png"
import background_2 from "images/background_2.png"


const PageDeclaration: React.FC = () => {
  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-astronomy theme-blue-coolGrey";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  return (
    <div className="nc-PageHomepage overflow-hidden relative">
      <Helmet>
        <title>THE BRUSSELS DECLARATION</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <div className={`absolute inset-x-0 top-0 h-200 w-25 pl-10 py-32 mt-90 flex flex-col overflow-hidden z-0`}>
        <div className={`nc-NcImage absolute inset-0  overflow-hidden`} data-nc-id="NcImage">
          <img src={background_1} className="object-cover opacity-50" />
        </div>
      </div>
      <div className={`absolute inset-r-0 top-0 h-200 right-0 w-25 pl-10 py-32 mt-180 flex flex-col overflow-hidden z-0`}>
        <div className={`nc-NcImage absolute inset-0 right-0  overflow-hidden`} data-nc-id="NcImage">
          <img src={background_2} className="object-cover opacity-50" />
        </div>
      </div>
      {/* ======= START CONTAINER ============= */}
      <SectionHeroRtbf className="pb-16 mt-8 mr-8 ml-8 lg:pb-20" />
      <div className={`container relative mb-16 text-center`}>

<a href="https://forms.office.com/pages/responsepage.aspx?id=Pvs8EP09REqDzkVaLSXbBi-20EFIgblKpHxwyv1Ol4JUQUMwNE9WS1pDMFRCV0daM0E1V0Q0NUUxQS4u" target="_blank" className="text-center text-xl md:text-xl font-semibold text-blue">Support the Brussels Declaration</a>
</div>
      <div className="container relative mb-16">
        {/* === SECTION 1 === */}
        
        <div className=" py-16">
          <BackgroundSection />
          
          <div className={`nc-SectionHomeContent`}>
     
      <div className={`nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between`}>
        <div className="text-left w-full  mx-auto ">
          <h1 className={`text-4xl md:text-5xl font-semibold`}>
          Public service media standing up for journalist safety, media freedom and democracy

          </h1>
        </div>
      </div>
      <div className={`nc-Section-Text mt-16 relative flex flex-col sm:flex-row sm:items-end justify-between`}>
        <div className="text-justify w-full mx-auto ">
          <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>Whereas the Universal Declaration of Human Rights has set out, since 1948, the right to freedom of opinion and expression, which “includes freedom to hold opinions without interference and to seek, receive and impart information and ideas through any media regardless of frontiers”<sup>1</sup> ;</p>
          <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>Whereas freedom of expression and freedom of the press are essential foundations of a democratic society, recognized by the European Court of Human Rights, which states that without “pluralism, tolerance and broadmindedness [...] there is no ‘democratic society’”<sup>2</sup>;</p>
          <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>Whereas these freedoms are threatened by the current rise in physical violence and online harassment against journalists, crews and media professionals, which disproportionately targets women and under-represented groups<sup>3</sup> — a trend fostered by what the UNESCO calls “a climate of endemic anti-press rhetoric, including by political leaders”;</p>
          <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>Whereas this trend is accelerating in the wake of the COVID-19 pandemic, increasing self-censorship among journalists, driving more professionals away from the news and media industry, and weakening pluralism and democracy as a result;</p>
          <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>Now, therefore,</p>        
          <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>All undersigned media organizations set out the following principles, which they shall stand by and advocate for, both individually and through concerted action.</p>
       
       
        </div>
      </div>
    </div>
    <hr className={`content-spacer`}></hr>
    <div className={`nc-SectionHomeContent `}>
      <div className={`nc-Section-Text mt-16 relative flex flex-col sm:flex-row sm:items-end justify-between`}>
        <div className="text-justify w-full mx-auto ">
          <h3 className={`text-2xl md:text-2xl font-semibold mb-8`}>PRINCIPLES</h3>

          <h2 className={`text-6xl md:text-6xl font-semibold text-grey`}>1.</h2>
          <h3 className={`text-xl md:text-xl font-semibold text-grey`}>Enhancing the safety of journalists, crews and media professionals</h3>
          <p className={`text-xl md:text-xl mb-8 mt-8 font-medium text-grey`}>All journalists, crew members and media professionals need to be able to safely perform their duties. To that end, the signatories commit to providing the resources and support their employees need to protect themselves from physical violence and online harassment, while raising public awareness about the impact of those attacks on democracy.</p>

          <h2 className={`text-6xl md:text-6xl font-semibold text-grey`}>2.</h2>
          <h3 className={`text-xl md:text-xl font-semibold text-grey`}>Standing up for the independence of public service media</h3>
          <p className={`text-xl md:text-xl mb-8 mt-8 font-medium text-grey`}>The signatories commit to publicly condemning any attempt to undermine the independence of public service media — whether it’s through political pressure, financial threats or retribution, harassment or attacks against employees, or antimedia rhetoric. They also commit to improving understanding of the distinction between public and state broadcasters.</p>

          <h2 className={`text-6xl md:text-6xl font-semibold text-grey`}>3.</h2>
          <h3 className={`text-xl md:text-xl font-semibold text-grey`}>Fostering an informed and civil democratic debate</h3>
          <p className={`text-xl md:text-xl mb-8 mt-8 font-medium text-grey`}>In response to the global disinformation crisis, the signatories commit to providing trusted news and information, supporting fact-checking initiatives, boosting media literacy, and monitoring and removing hate speech from their platforms. They also pledge to call on social media companies and regulators to eradicate online hate on third-party platforms.</p>

          <h2 className={`text-6xl md:text-6xl font-semibold text-grey`}>4.</h2>
          <h3 className={`text-xl md:text-xl font-semibold text-grey`}>Supporting a strong news ecosystem with a diversity of sources</h3>
          <p className={`text-xl md:text-xl mb-8 mt-8 font-medium text-grey`}>A diverse mix of public, community and private news media is fundamental to a strong news ecosystem and healthy democracy. Consequently, the signatories commit to collaborating with other domestic media outlets to protect local journalism — including sharing best practices on journalist safety and speaking with one voice on common challenges.</p>

          <h2 className={`text-6xl md:text-6xl font-semibold text-grey`}>5.</h2>
          <h3 className={`text-xl md:text-xl font-semibold text-grey`}>Promoting diversity, equity and inclusion within our organizations and in the societies we serve</h3>
          <p className={`text-xl md:text-xl mb-8 mt-8 font-medium text-grey`}>In order to uphold the ideals of pluralism, tolerance and broadmindedness that underpin democratic societies, the signatories commit to more accurately reflecting the diverse makeup and perspectives of the populations they serve, both in their programming and workforce — while also leading efforts to make their workplace inclusive for everyone.</p>
        </div>
      </div>
    </div>
    <hr className={`content-half-spacer mt-8 mb-16`}></hr>
    <div className={`nc-SectionHomeContent `}>
      <div className={`nc-Section-Text mt-16 relative flex flex-col sm:flex-row sm:items-end justify-between`}>
        <div className="text-justify w-full mx-auto ">

          
        <p className={`text-l md:text-l mb-4 mt-4 text-grey`}><sup className="mr-2" >1.</sup> <a href="https://www.un.org/en/about-us/universal-declaration-of-human-rights" target="_blank" className="text-underline">Universal Declaration of Human Rights</a>, as proclaimed on December 10, 1948.</p>
        <p className={`text-l md:text-l mb-4 mt-4 text-grey`}><sup className="mr-2" >2.</sup> <a href="https://globalfreedomofexpression.columbia.edu/cases/handyside-v-uk/#:~:text=of%20the%20%20population.-,Such%20are%20the%20demands%20of%20that%20pluralism%2C%20tolerance%20and%20broadmindedness,to%20the%20legitimate%20aim%20pursued.%E2%80%9D" target="_blank" className="text-underline">Handyside v. United Kingdom</a> (December 7, 1976), Columbia University: Global Freedom of Expression.</p>
        <p className={`text-l md:text-l mb-4 mt-4 text-grey`}><sup className="mr-2" >3.</sup> See reports conducted by <a href="https://unesdoc.unesco.org/ark:/48223/pf0000375136" target="_blank" className="text-underline">UNESCO</a>,  <a href="https://www.iwmf.org/wp-content/uploads/2018/09/Attacks-and-Harassment.pdf" target="_blank" className="text-underline">International Women’s Media Foundation</a>,  <a href="https://rsf.org/sites/default/files/sexisms_toll_on_journalism.pdf" target="_blank" className="text-underline">Reporters Without Borders</a> and  <a href="https://decoders.amnesty.org/projects/troll-patrol/findings#what_did_we_find_container" target="_blank" className="text-underline">Amnesty International</a>.</p>
        <p className={`text-l md:text-l mb-4 mt-4 text-grey`}><sup className="mr-2" >4.</sup> <a href="https://unescolive.wabamedia.co/" target="_blank" className="text-underline">Highlights from the 2020 UNESCO Director-General’s Report on the Safety of Journalists and the Danger of Impunity.</a></p>
        <p className={`text-l md:text-l mb-4 mt-4 text-grey`}><sup className="mr-2" >5.</sup> <a href="https://rm.coe.int/final-version-annual-report-2021-en-wanted-real-action-for-media-freed/1680a2440e" target="_blank" className="text-underline">Wanted! Real action for media freedom in Europe</a>, Annual Report by the partner organisations to the Council of Europe Platform to Promote the Protection of Journalism and Safety of Journalists, Council of Europe, April 2021.</p>

        </div>
      </div>
    </div>
    

        </div>

      </div>
    </div>
  );
};

export default PageDeclaration;
