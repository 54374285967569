import React, { FC } from "react";
import { useHistory ,useLocation } from 'react-router-dom';

import Logo from "components/Logo/Logo";
import Navigation from "components/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "components/Button/ButtonPrimary";
import ButtonSecondary from "components/Button/ButtonSecondary";
import MenuBar from "components/MenuBar/MenuBar";
import DarkModeContainer from "containers/DarkModeContainer/DarkModeContainer";

export interface MainNav1Props {
  isTop: boolean;
}

const MainNav1: FC<MainNav1Props> = ({ isTop }) => {
  const location = useLocation()
  const history = useHistory()

  console.log("location",location.pathname)
  return (
    <div
      className={`nc-MainNav1 relative z-10 ${
        isTop ? "onTop " : "notOnTop backdrop-filter"
      }`}
    >
      <div className="container py-5 relative flex items-center space-x-4 xl:space-x-8">
        <div className="flex flex-grow justify-start items-center space-x-4 sm:space-x-10 2xl:space-x-14">
          {/* <Logo /> */}
          <Navigation />
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-1">
            <div className="px-1" />
            {location.pathname.indexOf('fr') === -1 &&
            <>
              <ButtonPrimary href={`${location.pathname}`} className="newPrimary">English</ButtonPrimary>
              <ButtonSecondary href={`/fr${location.pathname}`} className="newPrimary">Français</ButtonSecondary>
            </>
            }
            {location.pathname.indexOf('fr') > -1 &&
            <>
              <ButtonSecondary href={`${location.pathname.replace("/fr/", "/").replace("/fr", "/")}`} className="newPrimary">English</ButtonSecondary>
              <ButtonPrimary href={`${location.pathname}`} className="newPrimary">Français</ButtonPrimary>
            </>
            }
          </div>
          <div className="flex items-center xl:hidden">
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
