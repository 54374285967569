import React, { FC } from "react";
import { PostDataType } from "data/types";
import NcImage from "components/NcImage/NcImage";
import ButtonSecondary from "components/Button/ButtonSecondary";
import Card5 from "components/Card5/Card5";
import featuredImage from "images/header_3.png"
export interface SectionHeroRtbfProps {
  className?: string;
}

const SectionHeroRtbf: FC<SectionHeroRtbfProps> = ({ className = "" }) => {


  return (
    <div className={`nc-SectionHeroRtbf ${className}`}>
         <div className="aspect-h-5 aspect-w-5 sm:aspect-w-8 lg:aspect-w-14">
        <NcImage
          containerClassName="absolute inset-0 rounded-[40px] overflow-hidden"
          src={featuredImage}
        />
        <span className="absolute inset-0 rounded-[40px] bg-black bg-opacity-0"></span>
        <div className="absolute inset-0 ">
          <div className="max-w titleDe">
            <h1 className="text-2xl sm:text-4xl md:text-6xl lg:text-6xl align-middle text-center font-bold text-white">
              <span className="line-clamp-2"> La Déclaration de Bruxelles</span>
            </h1>
          
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionHeroRtbf;
