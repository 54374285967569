import React, { useEffect, useState } from "react";
import NcImage from "components/NcImage/NcImage";

import SectionVideos from "./SectionVideos";
import SectionHero2 from "./SectionHero2";
import becomAuthorImg from "images/BecomeAnAuthorImg.png";
import { DEMO_POSTS, DEMO_POSTS_VIDEO } from "data/posts";
import { DEMO_AUTHORS } from "data/authors";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionAds from "./SectionAds";
import SectionSliderNewAuthors from "components/SectionSliderNewAthors/SectionSliderNewAuthors";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import { TaxonomyType } from "data/types";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionGridPosts from "./SectionGridPosts";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionTrending from "./SectionTrending";
import SectionMagazine6 from "./SectionMagazine6";
import ButtonPrimary from "components/Button/ButtonPrimary";
import ButtonSecondary from "components/Button/ButtonSecondary";

import header_layer_black from "images/header_layer_black.jpg"
import ReactPlayer from "react-player";
import NcPlayIcon from "components/NcPlayIcon/NcPlayIcon";
import background_1 from "images/background_1.png"
import background_2 from "images/background_2.png"

const PageHomepage: React.FC = () => {
  const [isPlay, setIsPlay] = useState(false);


  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-astronomy theme-blue-coolGrey";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  return (
    <div className="nc-PageHomepage relative">
      <Helmet>
        <title>THE BRUSSELS DECLARATION</title>
      </Helmet>
      {/* ======== BG GLASS ======== */}
      <div className={`absolute inset-x-0 top-0 h-200 w-25 pl-10 py-32 mt-90 flex flex-col overflow-hidden z-0`}>
        <div className={`nc-NcImage absolute inset-0  overflow-hidden`}
          data-nc-id="NcImage">
          <img src={background_1}
            className="object-cover opacity-50" />
        </div>
      </div>
      <div className={`absolute inset-r-0 top-0 h-200 right-0 w-25 pl-10 py-32 mt-180 flex flex-col overflow-hidden z-0`}>
        <div className={`nc-NcImage absolute inset-0 right-0  overflow-hidden`}
          data-nc-id="NcImage">
          <img src={background_2}
            className="object-cover opacity-50" />
        </div>
      </div>
      <div className={`absolute inset-x-0 top-0 h-200 w-25 pl-10 py-32 mt-270 flex flex-col overflow-hidden z-0`}>
        <div className={`nc-NcImage absolute inset-0  overflow-hidden`}
          data-nc-id="NcImage">
          <img src={background_1}
            className="object-cover opacity-50" />
        </div>
      </div>
      <div className={`absolute inset-r-0 top-0 h-200 right-0 w-25 pl-10 py-32 mt-350 flex flex-col overflow-hidden z-0`}>
        <div className={`nc-NcImage absolute inset-0 right-0  overflow-hidden`}
          data-nc-id="NcImage">
          <img src={background_2}
            className="object-cover opacity-50" />
        </div>
      </div>
      <div className={`absolute inset-x-0 bottom-0 h-100 w-25 pl-10 py-32  flex flex-col z-0`}>
        <div className={`nc-NcImage absolute inset-0`}
          data-nc-id="NcImage">
          <img src={background_1}
            className="object-cover opacity-50" />
        </div>
      </div>
      {/* ======== ALL SECTIONS ======== */}
      <div className="relative">
        <div className="relative pb-20 md:py-16 lg:py-32 bg-black">
          <div className="flex w-full mb-10  md:absolute md:right-0 md:top-0 md:bottom-0 md:mb-0">
            <div className="hidden md:block absolute top-0 left-0 bottom-0  from-black bg-gradient-to-r"></div>
            <img className="w-full h-full object-cover"
              src={header_layer_black}
              alt="" />
          </div>
          <div className="container relative z-10 text-neutral-100">
            <div className="max-w-4xl">
              <h1 className="font-bold text-4xl md:text-5xl xl:text-6xl mt-0 md:!leading-[110%] ">
                Making a choice for
                democracy
              </h1>
              <p className="mt-7 text-base lg:text-xl text-neutral-300 ">
                THE BRUSSELS DECLARATION: STANDING UP FOR JOURNALIST SAFETY AND MEDIA FREEDOM</p>
              <p className="mt-7 text-base lg:text-xl text-neutral-300 ">Violence and online harassment against media professionals are on the rise
                worldwide — and these attacks are disproportionately targeting women and
                members of under-represented groups.</p>
              <p className="mt-7 text-base lg:text-xl text-neutral-300 ">This worrying trend is prompting some to self-censor or leave the profession
                altogether, limiting the ability of news media to properly do their jobs.
                This is eroding a key foundation of any democratic society — freedom of the
                press.</p>
              <p className="mt-7 text-base lg:text-xl text-neutral-300 ">We must reverse this trend. Public service media and their allies are joining
                together to protect journalists and media professionals.</p>
              <div className="flex space-x-4 mt-11 text-center">
                <ButtonPrimary href={`/declaration`}>Read the Brussels Declaration</ButtonPrimary>
                <ButtonSecondary href={`/signatory`}>Signatory parties</ButtonSecondary>
              </div>
            </div>
          </div>

        </div>
         {/*<div className="container relative">

          <div className="videoMargin group aspect-w-16 aspect-h-16 sm:aspect-h-9 bg-neutral-800 rounded-3xl overflow-hidden border-0 border-transparent dark:border-neutral-900 sm:rounded-[50px] sm:border-[10px]">
            {
              isPlay ? (
                <ReactPlayer url={`https://player.vimeo.com/external/496576850.hd.mp4?s=17b8e391ef0dd67993cd6805e33a327b785dc0e4&profile_id=175`}
                  playing
                  width="100%"
                  height="100%"
                  controls
                  light={``}
                  playIcon={<NcPlayIcon />} />
              ) : (
                <>
                  <div onClick={
                    () => setIsPlay(true)
                  }
                    className="cursor-pointer absolute inset-0 flex items-center justify-center z-10">
                    <NcPlayIcon />
                  </div>
                  <NcImage containerClassName="absolute inset-0 " className="object-cover w-full h-full transform transition-transform group-hover:scale-105 duration-300 nc-will-change-transform"
                    src={`video.thumbnail`}
                    title={'`video.title`'}
                    alt={`video.title`} />
                </>
              )
            } </div>
        </div> */}

        <div className="container  relative videoMargin mb-16">
          {/* === SECTION 1 === */}
          <div className=" py-16">
            <BackgroundSection />

            <div className={`nc-SectionHomeContent`}>

              <div className={`nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between`}>
                <div className="text-left w-full  mx-auto ">
                  <h1 className={`text-4xl md:text-5xl font-semibold`}>
                    Context
                  </h1>
                  <h2 className={`text-xl md:text-2xl font-semibold mt-8`}>
                    THE INCREASING THREATS TO JOURNALISTS’ SAFETY AND MEDIA FREEDOM
                  </h2>
                </div>
              </div>
              <div className={`nc-Section-Text mt-8 relative flex flex-col sm:flex-row sm:items-end justify-between`}>
                <div className="text-justify w-full mx-auto ">
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>Physical violence and online harassment against journalists, news
                    crews and media professionals are on the rise worldwide — and
                    this trend is accelerating in the wake of the COVID-19 pandemic.</p>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey text-80`}>Media freedom alerts to the Council of <a href="https://www.coe.int/en/web/media-freedom/all-alerts?p_p_id=sojdashboard_WAR_coesojportlet&p_p_lifecycle=0&p_p_col_id=column-4&p_p_col_count=3&_sojdashboard_WAR_coesojportlet_selectedStringFilters=special.killed%2Cyear.2021&_sojdashboard_WAR_coesojportlet_selectedCategories=" target="_blank" className="text-underline">Europe’s Safety of
                    Journalists Platform</a><span className="font-bold"> rose by almost <span className="text-highlight">40%</span></span><span className="text-highlight"> between 2019 and 2020, </span><span className="font-bold text-highlight">with a record number of those alerts involving physical attacks, harassment or intimidation.¹</span>
                  </p>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>Meanwhile, emergency laws and regulations enacted by
                    governments in response to the pandemic led to “arbitrary
                    interferences in the legitimate work of journalists and news
                    organisations,” resulting in “severe infringements of Convention
                    rights under Article 10” of the European Convention on Human
                    Rights.<sup>2</sup> UNESCO also noted “increasing intolerance towards
                    reporting, fostered by a climate of endemic anti-press rhetoric,
                    including by political leaders....”<sup>3</sup> This intolerance has been
                    especially exemplified by abuses and insults targeting journalists
                    covering COVID-related protests in <a href="https://ipi.media/disturbing-pattern-of-violence-and-harassment-at-covid-related-protests-across-europe/" target="_blank" className="text-underline">Europe</a> and the Black Lives Matter protests in the <a href="https://www.theguardian.com/media/2020/jun/05/im-getting-shot-attacks-on-journalists-surge-in-us-protests" target="_blank" className="text-underline">U.S.</a>
                  </p>


                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>Fatal attacks against journalists are also no longer confined to
                    war-torn countries. In July 2021, Dutch investigative journalist <a href="https://www.bbc.com/news/world-europe-57853004" target="_blank" className="text-underline">Peter R. de Vries</a> died after being shot in central Amsterdam,
                    while Georgian cameraman <a href="https://www.theguardian.com/media/2021/jul/11/georgian-cameraman-dies-after-attack-by-far-right-anti-lgbtq-mob" target="_blank" className="text-underline">Aleksandre Lashkarava</a> was found
                    dead at his home after being severely beaten by anti-LGBTQ+
                    protesters during a Pride march in Tbilisi. Earlier in April, Greek
                    crime reporter <a href="https://www.theguardian.com/world/2021/apr/09/greek-journalist-george-karaivaz-shot-dead-in-athens" target="_blank" className="text-underline">Giorgos Karaivaz</a> was killed by gunmen on
                    motorbikes near his home in Athens. These fatal attacks are part
                    of a wider trend.</p>

                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey text-80`}>UNESCO found that <span className="font-bold text-highlight">61% of all journalists killed worldwide
                    in 2019 lost their lives in countries with no armed conflict</span>, up from 50% in 2016.⁴</p>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>The Public Media Alliance noted that the shooting of Mr. De Vries
                    occurred in a context where “attacks on journalists are rising
                    in well-consolidated democracies across Europe – even those
                    who enjoy high levels of press freedom,” citing specific cases
                    of physical violence and legislative threats in countries like the
                    Netherlands, Germany, Italy and the United Kingdom.⁵</p>

                  <h2 className={`text-xl md:text-2xl font-semibold mt-8`}>
                    WOMEN AND UNDER-REPRESENTED GROUPS AS TARGETS OF ATTACKS
                  </h2>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>Attacks on journalists disproportionately target women and
                    members of under-represented groups, as documented in a
                    series of reports conducted by  <a href="https://en.unesco.org/themes/safety-journalists/dgreport" target="_blank" className="text-underline">UNESCO</a>, the  <a href="https://rm.coe.int/final-version-annual-report-2021-en-wanted-real-action-for-media-freed/1680a2440e" target="_blank" className="text-underline">Council of Europe</a>,
                    the  <a href="https://www.iwmf.org/wp-content/uploads/2018/09/Attacks-and-Harassment.pdf" target="_blank" className="text-underline">International Women’s Media Foundation</a>,  <a href="https://rsf.org/sites/default/files/sexisms_toll_on_journalism.pdf" target="_blank" className="text-underline">Reporters Without
                      Borders</a> and  <a href="https://decoders.amnesty.org/projects/troll-patrol/findings#what_did_we_find_container" target="_blank" className="text-underline">Amnesty International</a>.</p>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>According to UNESCO, women are more likely to be subjected
                    to “intimidation, threats, and harassment of a sexual or sexist
                    nature, and also to particular forms of online attacks, such as
                    cyberstalking, doxxing and trolling.”<sup>6</sup> In 2020, the organization
                    conducted a <a href="https://unesdoc.unesco.org/ark:/48223/pf0000375136" target="_blank" className="text-underline">survey</a> of <span className="text-highlight">714 women journalists in 15 countries
                    and found that 73% of them had experienced online violence,
                    with 25% threatened with physical violence and 18% with
                    sexual violence.</span></p>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey text-80`}><span className="font-bold">Online attacks often translate into real-life abuses</span> - 20% of women saying they were subjected to offline attacks and abuses in connection with violence they had experienced online.⁷</p>

                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>In March 2021, Reporters Without Borders noted in a  <a href="https://rsf.org/sites/default/files/sexisms_toll_on_journalism.pdf" target="_blank" className="text-underline">new report</a> that being “a member of a minority constitutes an additional
                    risk for women journalists.” They reported that sexist and sexual violence are often combined with other types of insults: racism, lesbophobia or transphobia, and comments or insults based on religion.<sup>8</sup> Racialized women journalists are also more likely to be targeted by online trolling, according to data compiled by <a href="https://decoders.amnesty.org/projects/troll-patrol/findings#what_did_we_find_container" target="_blank" className="text-underline">Amnesty International</a>.</p>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey `}><span className="text-highlight">Women of colour in the media or politics were <span className="font-bold">34% more likely</span> than white women <span className="font-bold">to be mentioned in abusive or problematic tweets</span>, and that number rises to <span className="font-bold">84% for Black women</span>.⁹</span></p>

                  <h2 className={`text-xl md:text-2xl font-semibold mt-8`}>PLURALISM AND DEMOCRACY AT STAKE</h2>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>States responded to the COVID-19 pandemic in a way that “led to
                    serious challenges to press freedom” and “restricted journalists’
                    ability to cover the public health situation freely.”¹⁰ These restrictions
                    were “implemented deliberately or out of neglect for the media’s
                    crucial role in times of crisis.”<sup>11</sup> While lifting those restrictions
                    is critical, it won’t be enough to stop and reverse the current
                    decline in media freedom. Physical violence and online harassment
                    also have tremendous — although more insidious — impacts.</p>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey text-80`}>
                    After conducting a survey in 12 countries, Reporters Without Borders found that <span className="font-bold">48% of women journalists already resort to self-censorship</span> to avoid exposing themselves to violence. Another <span className="font-bold">21% have resigned or are considering not renewing</span> their contracts.¹²
                  </p>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>Threats to journalists’ safety are eroding media freedom at an
                    escalating pace. The fact that women and members of under-represented
                    groups are more exposed to these threats also
                    limits the ability of news media to report diverse perspectives,
                    shrinking pluralism and weakening democracy.</p>


                </div>
              </div>
            </div>
            <hr className={`content-spacer`}></hr>
            <div className={`nc-SectionHomeContent `}>
              <div className={`nc-Section-Text mt-16 relative flex flex-col sm:flex-row sm:items-end justify-between`}>
                <div className="text-justify w-full mx-auto ">
                  <h1 className={`text-4xl md:text-5xl font-semibold`}>
                    Objectives of the Brussels Declaration</h1>
                  <h2 className={`text-xl md:text-2xl font-semibold mt-8 mb-8`}>
                    A COLLECTIVE COURSE OF ACTION GROUNDED IN KEY PRINCIPLES OF PUBLIC SERVICE
                    MEDIA
                  </h2>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>The Brussels Declaration is a joint initiative of Canada’s national public broadcaster, CBC/Radio-Canada, and Belgium’s French and Flemish public broadcasters, RTBF and VRT. The Declaration
                    was officially presented to all participants of the Brussels <a href="https://pbibrussels2021.be/" target="_blank" className="text-underline">Public Broadcasters International (PBI) conference</a> held online on September 30, 2021. With the theme “A Choice for Democracy,”
                    the event highlighted the role of public media as “a cornerstone of democracy and the rule of law.”</p>

                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>
                    The objective of the Brussels Declaration was to express the
                    common concern of signatories regarding the increased threats
                    to journalists’ safety and media freedom. The Declaration also
                    provides a collective course of action to help alleviate the
                    problem. <span className="font-bold">Five (5) key principles</span> were established to guide the signatories’ efforts:</p>
                  <ol className={`text-xl md:text-xl mb-8 font-semibold text-grey numerotedList`}>
                    <li>Enhancing the safety of journalists, crews and media professionals</li>
                    <li>Standing up for the independence of public service media</li>
                    <li>Fostering an informed and civil democratic debate</li>
                    <li>Supporting a strong news ecosystem with a diversity of sources</li>
                    <li>Promoting diversity, equity and inclusion within our organizations and in the societies we serve</li>
                  </ol>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>The Declaration outlines how public media organizations
                    could translate these principles into action. Signatories of
                    the Declaration are also expected to identify and share best
                    practices, with the goal of maximizing their impact.</p>
                  <h2 className={`text-xl md:text-2xl font-semibold mt-8 mb-8`}>
                    FOSTERING GLOBAL AWARENESS, MOBILIZATION AND ACTION
                  </h2>

                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>Public broadcasters are only one part of the solution. Although
                    they can help mitigate some of the worst impacts of the crisis
                    afflicting democracies, they cannot act alone.</p>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>That’s why another objective of the Brussels Declaration is to
                    raise global awareness of the connection between journalists’
                    safety, media freedom and democracy. This includes speaking
                    out against elected officials and other actors who directly or
                    indirectly fuel distrust toward news media, attack journalists
                    and media professionals, or act in a way that undermines the
                    independence of public media. The signatories are also calling on third parties, such as social media companies and regulators, to address the threat of online hate that specifically targets journalists on external platforms.</p>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>The Brussels Declaration also comes at a time of increasing
                    momentum on the world stage — especially in the European
                    Union. On September 15, the European Commission put forward
                    recommendations on the safety of journalists and other media
                    professionals, calling on member states to take “decisive action
                    to make the EU a safer place for journalists.”<sup>13</sup> This new set
                    of recommendations focuses on issues such as “protests and
                    demonstrations; online safety and digital empowerment; female
                    journalists and journalists belonging to minority groups.”<sup>14</sup> The Commission is also expected to present a European Media
                    Freedom Act in 2022.</p>
                  <p className={`text-xl md:text-xl mb-8 font-medium text-grey`}>
                    The signatories of the Brussels Declaration want to build on
                    this momentum to achieve a larger global mobilization and
                    trigger action from key stakeholders, including governments
                    and public officials, multilateral organizations, NGOs, private and
                    community news media, and social media companies. The goal is
                    to rally more stakeholders around the principles of the Brussels
                    Declaration, which should be applied not only to public media
                    but to civil society as a whole.
                  </p>
                </div>
              </div>
            </div>
            <hr className={`content-half-spacer mt-8 mb-16`}></hr>
            <div className={`nc-SectionHomeContent `}>
              <div className={`nc-Section-Text mt-16 relative flex flex-col sm:flex-row sm:items-end justify-between`}>
                <div className="text-justify w-full mx-auto ">


                  <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">1.</sup>
                    Council of Europe, <a href="https://rm.coe.int/final-version-annual-report-2021-en-wanted-real-action-for-media-freed/1680a2440e" target="_blank" className="text-underline">Wanted! Real action for media freedom in Europe</a>, Annual Report by the partner organisations to the Council of Europe
                    Platform to Promote the Protection of Journalism and Safety of Journalists, April 2021, p. 7</p>
                  <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">2.</sup>
                    Council of Europe, p. 6.</p>
                  <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">3.</sup>
                    UNESCO, <a href="https://www.unesco.org/reports/sjdi/2020/" target="_blank" className="text-underline">Highlights</a> from the 2020 UNESCO Director-General’s Report on the Safety of Journalists and the Danger of Impunity.</p>
                  <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">4.</sup>
                    UNESCO.</p>
                  <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">5.</sup>
                    Public Media Alliance, <a href="https://www.publicmediaalliance.org/threats-to-journalists-on-the-rise-across-europe/" target="_blank" className="text-underline">Threats to Journalists on the Rise Across Europe</a>, July 9, 2021.</p>
                  <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">6.</sup>
                    UNESCO.</p>
                  <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">7.</sup>
                    Julie Posetti and al., <a href="https://unesdoc.unesco.org/ark:/48223/pf0000375136" target="_blank" className="text-underline">Online Violence Against Women Journalists: A Global Snapshot of Incidence and Impacts</a>, UNESCO, 2020.</p>
                  <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">8.</sup>
                    Reporters Without Borders, <a href="https://rsf.org/sites/default/files/sexisms_toll_on_journalism.pdf" target="_blank" className="text-underline">Sexism’s Toll on Journalism</a>, March 2021, p. 20.</p>
                  <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">9.</sup>
                    Amnesty International, <a href="https://decoders.amnesty.org/projects/troll-patrol/findings#what_did_we_find_container" target="_blank" className="text-underline">Troll Patrol Findings</a>: Using Crowdsourcing, Data Science & Machine Learning to Measure Violence and Abuse Against
                    Women on Twitter, 2018.</p>
                  <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">10.</sup>
                    Council of Europe, <a href="https://rm.coe.int/final-version-annual-report-2021-en-wanted-real-action-for-media-freed/1680a2440e" target="_blank" className="text-underline">Wanted! Real action for media freedom in Europe</a>, Annual Report by the partner organisations to the Council of Europe
                    Platform to Promote the Protection of Journalism and Safety of Journalists, April 2021, p. 9.</p>
                  <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">11.</sup>
                    Ibid., p. 6.</p>
                    <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">12.</sup>
                    Reporters Without Borders, p. 25.</p>
                    <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">13.</sup>
                    European Commission, <a href="https://ec.europa.eu/commission/presscorner/detail/en/ip_21_4632" target="_blank" className="text-underline">State of the Union: Commission calls on Member States to improve journalists' safety across the EU,</a> September 16, 2021..</p>
                    <p className={`text-l md:text-l mb-4 mt-4 text-grey`}>
                    <sup className="mr-2">14.</sup>
                    Ibid.</p>
               
                </div>
              </div>
            </div>


          </div>

        </div>


        <div className="container relative mb-16 mt-16">
          {/* === SECTION 1 === */}
          <div className=" py-16">
            <BackgroundSection />

            <div className={`nc-SectionHomeContent`}>

              <div className={`nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between`}>
                <div className="text-left w-full  mx-auto ">
                  <h1 className={`text-2xl md:text-3xl font-semibold`}>
                    USEFUL LINKS
                  </h1>
                  <h2 className={`text-xl md:text-2xl font-semibold mt-8 mb-4 text-grey`}>
                    UNESCO reports :
                  </h2>
                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">
                    <a href="https://www.unesco.org/reports/sjdi/2020/" target="_blank">
                      Highlight from the 2020 UNESCO Director-General Report on the Safety of Journalists and the Danger of Impunity
                    </a>
                  </p>
                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">
                    <a href="https://unesdoc.unesco.org/ark:/48223/pf0000375136" target="_blank">
                      Online Violence Against Women Journalists: A Global Snapshot of Incidence and Impacts
                    </a>
                  </p>
                  <h2 className={`text-xl md:text-2xl font-semibold mt-8 mb-4 text-grey`}>
                    Council of Europe resources :
                  </h2>
                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">

                    <a href="https://rm.coe.int/final-version-annual-report-2021-en-wanted-real-action-for-media-freed/1680a2440e" target="_blank">
                      Wanted! Real action for media freedom in Europe
                    </a>
                  </p>
                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">

                    <a href="https://www.coe.int/en/web/media-freedom/all-alerts?p_p_id=sojdashboard_WAR_coesojportlet&p_p_lifecycle=0&p_p_col_id=column-4&p_p_col_count=3&_sojdashboard_WAR_coesojportlet_selectedStringFilters=special.killed%2Cyear.2021&_sojdashboard_WAR_coesojportlet_selectedCategories=" target="_blank">
                      Safety of Journalists Platform
                    </a>
                  </p>
                  <h2 className={`text-xl md:text-2xl font-semibold mt-8 mb-4 text-grey`}>
                    European Commission: :
                  </h2>
                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">

                    <a href="https://ec.europa.eu/commission/presscorner/detail/en/ip_21_4632" target="_blank">
                      Recommendations on the safety of
                      journalists and other media professionals
                    </a>
                  </p>

                  <h2 className={`text-xl md:text-2xl font-semibold mt-8 mb-4 text-grey`}>
                    Reporters Without Borders report :
                  </h2>
                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">

                    <a href="https://rsf.org/sites/default/files/sexisms_toll_on_journalism.pdf" target="_blank">
                      Sexism’s Toll on Journalism
                    </a>
                  </p>
                  <h2 className={`text-xl md:text-2xl font-semibold mt-8 mb-4 text-grey`}>
                    Amnesty International report :
                  </h2>
                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">

                    <a href="https://decoders.amnesty.org/projects/troll-patrol/findings#what_did_we_find_container" target="_blank">
                      Troll Patrol Findings
                    </a>
                  </p>
                  <h2 className={`text-xl md:text-2xl font-semibold mt-8 mb-4 text-grey`}>
                    International Women’s Media Foundation report :
                  </h2>
                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">

                    <a href="https://www.iwmf.org/wp-content/uploads/2018/09/Attacks-and-Harassment.pdf" target="_blank">
                      Attacks and Harassment: The Impact on Female Journalists and Their Reporting
                    </a>
                  </p>
                  <h2 className={`text-xl md:text-2xl font-semibold mt-8 mb-4 text-grey`}>
                    Guardian/Bellingcat report :
                  </h2>
                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">

                    <a href="https://www.theguardian.com/media/2020/jun/05/im-getting-shot-attacks-on-journalists-surge-in-us-protests" target="_blank">
                      Attacks on journalists surge in US protests
                    </a>
                  </p>

                  <h2 className={`text-xl md:text-2xl font-semibold mt-8 mb-4 text-grey`}>
                    Statements by the Global Task Force for public media :
                  </h2>
                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">
                    <a href="https://www.publicmediaalliance.org/the-increasing-threats-to-journalists-safety/" target="_blank">
                      The increasing threats to journalists’ safety
                    </a>
                  </p>
                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">
                    <a href="https://www.publicmediaalliance.org/attacks-on-public-media-are-an-attack-on-democracy/" target="_blank">
                      Attacks on public media are an attack on democracy
                    </a>
                  </p>
                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">
                    <a href="https://www.publicmediaalliance.org/global-task-force/" target="_blank">
                      Mandate and other statements
                    </a>
                  </p>

                  <h2 className={`text-xl md:text-2xl font-semibold mt-8 mb-4 text-grey`}>
                    <a href="https://www.publicmediaalliance.org/" target="_blank" className="text-underline">Public Media Alliance</a> :
                  </h2>
                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">
                    <a href="https://protect-eu.mimecast.com/s/XgbuCRL00u8wWkKCNEve2?domain=publicmediaalliance.org" target="_blank">
                      Threats to Journalists on the Rise Across Europe
                    </a>
                  </p>

                  <p className="text-underline text-l md:text-xl font-normal mt-8 text-grey">
                    <a href="https://www.publicmediaalliance.org/global-call-out-world-press-freedom-day-2021/" target="_blank">
                      Global Call Out: Information as a Public Good
                    </a>
                  </p>

                  <h2 className={`text-xl md:text-2xl font-semibold mt-8 mb-4 text-grey`}>
                    <a href="https://pbibrussels2021.be/" target="_blank" className="text-underline">PBI Brussels 2021</a>
                  </h2>


                </div>
              </div>


            </div>

          </div>


        </div>
      </div>
    </div>
  );
};

export default PageHomepage;
