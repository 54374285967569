import React, { FC,useState,useEffect,useRef,} from "react";
import LayoutPage from "components/LayoutPage/LayoutPage";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";
import NcLink from "components/NcLink/NcLink";
import { Helmet } from "react-helmet";
import NcImage from "components/NcImage/NcImage";
import axios from 'axios';
import { format } from 'date-fns';

export interface PageSignatoryProps {
  className?: string;
}

const PageSignatory: FC<PageSignatoryProps> = ({ className = "" }) => {
  let fullname = useRef() as React.MutableRefObject<HTMLInputElement>;
  let uid = useRef() as React.MutableRefObject<HTMLInputElement>;
  let order = useRef() as React.MutableRefObject<HTMLInputElement>;
  let title = useRef() as React.MutableRefObject<HTMLInputElement>;
  let titleFr = useRef() as React.MutableRefObject<HTMLInputElement>;
  let date = useRef() as React.MutableRefObject<HTMLInputElement>;
  let organisation = useRef() as React.MutableRefObject<HTMLInputElement>;
  let formForm = useRef() as React.MutableRefObject<HTMLInputElement>;
  let listList = useRef() as React.MutableRefObject<HTMLInputElement>;
  // let file = useRef() as React.MutableRefObject<File>;
  const [fileSelected, setFileSelected] = React.useState<File>() // also tried <string | Blob>

  const [editData, setEditData] = React.useState<any>();
  


  const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
      const fileList = e.target.files;
      if (!fileList) return;
      setFileSelected(fileList[0]);
  };
  const [parties, setParties] = useState([]);

    useEffect(() => {
      axios.get(`https://backend.develop.rtbf.flykube.agifly.cloud/api/v1/signatory`).then(response => {
          setParties(response.data.data)
      }).catch(err =>{
        console.log("error",err)
      })
    }, []);

    const handleDelete = function (e:any,id:Number) {
      e.preventDefault();

    console.log("delete",id)
    axios.post("https://backend.develop.rtbf.flykube.agifly.cloud/api/v1/signatory/remove", {user_id:id}).then(respone =>{
      axios.get(`https://backend.develop.rtbf.flykube.agifly.cloud/api/v1/signatory`).then(response => {
        setParties(response.data.data)
        // window.scrollTo(0, 0);
        listList.current.scrollIntoView({ behavior: 'smooth' })

    }).catch(err =>{
      console.log("error",err)
    })

  })};
  
  const handleEdit = function (e:any,id:Number, name:string, title:string, titleFr:string, organisation:string, order:Number) {
    e.preventDefault();
    const editDatas = {
      id:id,
      fullname:name,
      title:title,
      titleFr:titleFr,
      organisation:organisation,
      order:order
    }

    setEditData(editDatas)
    console.log("edit data",editData)
    formForm.current.scrollIntoView({ behavior: 'smooth' })

    // window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
    

    
  };
  


    
  const onEditFormSubmit = (e:any) => {
    e.preventDefault();
    console.log("submit",e)
    console.log("submit",fullname.current.value)
    console.log("submit",title.current.value)
    console.log("submit",organisation.current.value)
    console.log("submit",uid.current.value)
    console.log("submit",order.current.value)
    console.log("submit",fileSelected)

    var formData = new FormData();
    if (fileSelected) {
      formData.append("file", fileSelected);
      formData.append("id", uid.current.value);
      formData.append("fullname", fullname.current.value);
      formData.append("title", title.current.value);
      formData.append("titleFr", titleFr.current.value);
      formData.append("organisation", organisation.current.value);
      formData.append("order", order.current.value ? order.current.value : '0');
    }else{
      formData.append("fullname", fullname.current.value);
      formData.append("id", uid.current.value);
      formData.append("title", title.current.value);
      formData.append("titleFr", titleFr.current.value);
      formData.append("organisation", organisation.current.value);
      formData.append("order", order.current.value ? order.current.value : '0');
    }
axios.post("https://backend.develop.rtbf.flykube.agifly.cloud/api/v1/signatory/edit", formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
}).then(respone =>{
  uid.current.value = "";
  fullname.current.value = "";
  title.current.value = "";
  titleFr.current.value = "";
  organisation.current.value = "";
  order.current.value = "";
  setEditData({})
  setFileSelected(undefined)
  axios.get(`https://backend.develop.rtbf.flykube.agifly.cloud/api/v1/signatory`).then(response => {
    setParties(response.data.data)
    // window.scrollTo(0, 0);
    listList.current.scrollIntoView({ behavior: 'smooth' })

}).catch(err =>{
  console.log("error",err)
})
})
}

  const onFormSubmit = (e:any) => {
    e.preventDefault();
    console.log("submit e",e)
    console.log("submit name",fullname.current.value)
    console.log("submit title",title.current.value)
    console.log("submit organisation",organisation.current.value)
    console.log("submit",fileSelected)

    var formData = new FormData();
    if (fileSelected) {
      formData.append("file", fileSelected);
    }
    formData.append("fullname", fullname.current.value);
    formData.append("title", title.current.value);
    formData.append("titleFr", titleFr.current.value);
    formData.append("organisation", organisation.current.value);
    formData.append("order", order.current.value ? order.current.value : '0');
    axios.post("https://backend.develop.rtbf.flykube.agifly.cloud/api/v1/signatory/add", formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
}).then(respone =>{
  fullname.current.value = "";
  title.current.value = "";
  titleFr.current.value = "";
  organisation.current.value = "";
  order.current.value = "";
  setEditData({})
  setFileSelected(undefined)

  axios.get(`https://backend.develop.rtbf.flykube.agifly.cloud/api/v1/signatory`).then(response => {
    setParties(response.data.data)
    // window.scrollTo(0, 0);
    listList.current.scrollIntoView({ behavior: 'smooth' })

}).catch(err =>{
  console.log("error",err)
})
})


    // const credentials = {
    //   email :email.current.value,
    //   password :password.current.value,
    // };


    // axios.post(`https://backend.develop.rtbf.flykube.agifly.cloud/api/v1/auth/sign_in`,credentials).then(response => {
    //  const token = response.data.data.auth.token;

    //  console.log("response",response)
    //   cookies.set("op-token", token, {
    //     maxAge: 60 * 60 * 2,
    //     path: '/',
    //     sameSite: 'none',
    //     secure: true,
    //   });
    //   localStorage.setItem("op-token", token);
    //   history.push('/admin');


    // }).catch(err =>{
    //   console.log("error",err)
    // })

    // send to server with e.g. `window.fetch`
  }
  const handleChange = (e:any) => {

    console.log(".target.name",e.target.name)
    console.log(title)
    setEditData({
      id:uid.current.value,
      fullname:e.target.name === "fullname" ? e.target.value : fullname.current.value,
      title:e.target.name === "title" ? e.target.value : title.current.value,
      titleFr:e.target.name === "titleFr" ? e.target.value : titleFr.current.value,
      organisation:e.target.name === "organisation" ? e.target.value : organisation.current.value,
      order:e.target.name === "order" ? e.target.value : order.current.value
    })
    console.log("handleChange",{
      id:uid.current.value,
      fullname:e.target.name === "fullname" ? e.target.value : fullname.current.value,
      title:e.target.name === "title" ? e.target.value : title.current.value,
      titleFr:e.target.name === "titleFr" ? e.target.value : titleFr.current.value,
      organisation:e.target.name === "organisation" ? e.target.value : organisation.current.value,
      order:e.target.name === "order" ? e.target.value : order.current.value
    })
  };


  return (
    <div className={`nc-PageSignatory ${className}`} data-nc-id="PageSignatory">
      <Helmet>
        <title>Manage Signatory</title>
      </Helmet>
      <LayoutPage
        headingEmoji="🎉"
        heading="Listing Signatory"
      >
        <div className=" mx-auto" ref={listList}>
       
        <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-800">
              <thead className="bg-neutral-50 dark:bg-neutral-800">
                <tr className="text-left text-xs font-medium text-neutral-500 dark:text-neutral-300 uppercase tracking-wider">
                <th scope="col" className="px-6 py-3">
                    Order
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Fullname
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Title - En
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Title - Fr
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Company
                  </th>
                  

                  <th scope="col" className="relative px-6 py-3 text-right	" colSpan={2}>
                    <span className="text-right">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-neutral-900 divide-y divide-neutral-200 dark:divide-neutral-800">
                {parties && parties.map((item:any) => (
                  <tr key={item.id}>
                            <td className="px-6 py-4 whitespace-nowrap">
                    <h2 className="inline-flex line-clamp-2 text-sm font-semibold  dark:text-neutral-300">
                            {item.order}
                          </h2>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex items-center w-96 lg:w-auto max-w-md overflow-hidden">
                        <NcImage
                          containerClassName="flex-shrink-0 h-12 w-12 rounded-lg overflow-hidden lg:h-14 lg:w-14"
                          src={`https://backend.develop.rtbf.flykube.agifly.cloud/filestore/`+item.logo}
                        />
                        <div className="ml-4 flex-grow">
                          <h2 className="inline-flex line-clamp-2 text-sm font-semibold  dark:text-neutral-300">
                            {item.fullname}
                          </h2>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                    <h2 className="inline-flex line-clamp-2 text-sm font-semibold  dark:text-neutral-300">
                            {item.title}
                          </h2>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                    <h2 className="inline-flex line-clamp-2 text-sm font-semibold  dark:text-neutral-300">
                            {item.titleFr}
                          </h2>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-neutral-500 dark:text-neutral-400">
                      <span> {item.organisation ? item.organisation : "-"}</span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-neutral-300 text-green-600">
                      <a
                        href="#"
                        className="text-indigo-600 hover:text-indigo-900"
                        onClick={(e) => handleEdit(e,item.id,item.fullname, item.title,item.titleFr, item.organisation,item.order)} 
                      >
                        Edit
                      </a>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-neutral-300">
                      <a
                        href="#"
                        className="text-rose-600 hover:text-rose-900"
                        onClick={(e) => handleDelete(e,item.id)} 
                      >
                        Delete
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

        </div>
      </LayoutPage>
      <LayoutPage
        headingEmoji="🎉"
        heading={editData  && editData.id > 0 ? "Edit Signatory" : "Add Signatory"}
        
      >
        <div className="max-w-md mx-auto " ref={formForm}>
          {editData && editData.id > 0 ?
             <form className="grid grid-cols-1 gap-6" action="#" method="post" onSubmit={onEditFormSubmit}>
             <label className="block">
             <span className="text-neutral-800 dark:text-neutral-200">
                   Order ({editData.id} - {editData.fullname})
                 </span>
                 <Input
                   type="number"
                   placeholder=""
                   className="mt-1"
                   ref={order}
                   value={editData.order}
                   onChange={handleChange}
                   name="order"
                 />
                 <span className="text-neutral-800 dark:text-neutral-200">
                   Fullname
                 </span>
                 <Input
                   type="text"
                   placeholder=""
                   className="mt-1 hidden"
                   ref={uid}
                   value={editData.id}
                   onChange={handleChange}
                   name="id"
                   
                 />
                 <Input
                   type="text"
                   placeholder=""
                   className="mt-1"
                   ref={fullname}
                   value={editData.fullname}
                   onChange={handleChange}
                   name="fullname"
                 />
               </label>
               <label className="block">
                 <span className="text-neutral-800 dark:text-neutral-200">
                   Title - En (default)
                 </span>
                 <Input
                   type="text"
                   placeholder=""
                   className="mt-1"
                   ref={title}
                   value={editData.title}
                   onChange={handleChange}
                   name="title"
                 />
               </label>
               <label className="block">
                 <span className="text-neutral-800 dark:text-neutral-200">
                   Title - Fr
                 </span>
                 <Input
                   type="text"
                   placeholder=""
                   className="mt-1"
                   ref={titleFr}
                   value={editData.titleFr}
                   onChange={handleChange}
                   name="titleFr"
                 />
               </label>
               <label className="block">
                 <span className="text-neutral-800 dark:text-neutral-200">
                   Organisation
                 </span>
                 <Input
                   type="text"
                   placeholder=""
                   className="mt-1"
                   ref={organisation}
                   value={editData.organisation}
                   onChange={handleChange}
                    name="organisation"
                 />
               </label>
               <label className="block">
   
                 <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                   Logo
                 </span>
                 <label className="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
           <svg className="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
               <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
           </svg>
           <span className="mt-2 text-base leading-normal"> {fileSelected ? fileSelected.name : 'Select a file'}</span>
           <input type='file' onChange={handleImageChange}   className="hidden" />
       </label>
   
                 {/* <Input type="file" onChange={handleImageChange}  className="mt-1" /> */}
               </label>
               <ButtonPrimary type="submit">Edit</ButtonPrimary>
             </form>
          :
          <form className="grid grid-cols-1 gap-6" action="#" method="post" onSubmit={onFormSubmit}>
                <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Order
              </span>
              <Input
                type="number"
                placeholder=""
                className="mt-1"
                ref={order}
              />
            </label>
          <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Fullname
              </span>
              <Input
                type="text"
                placeholder=""
                className="mt-1"
                ref={fullname}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Title - En (default)
              </span>
              <Input
                type="text"
                placeholder=""
                className="mt-1"
                ref={title}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Title - Fr
              </span>
              <Input
                type="text"
                placeholder=""
                className="mt-1"
                ref={titleFr}
              />
            </label>
            <label className="block">
                 <span className="text-neutral-800 dark:text-neutral-200">
                   Organisation
                 </span>
                 <Input
                   type="text"
                   placeholder=""
                   className="mt-1"
                   ref={organisation}
                 />
               </label>
            <label className="block">

              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Logo
              </span>
              <label className="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
        <svg className="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
        </svg>
        <span className="mt-2 text-base leading-normal"> {fileSelected ? fileSelected.name : 'Select a file'}</span>
        <input type='file' onChange={handleImageChange}   className="hidden" />
    </label>

              {/* <Input type="file" onChange={handleImageChange}  className="mt-1" /> */}
            </label>
            <ButtonPrimary type="submit">Continue</ButtonPrimary>
          </form>
          
          }
       
        </div>
      </LayoutPage>
    </div>
  );
};

export default PageSignatory;
