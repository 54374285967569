import React from "react";
import NavigationItem from "./NavigationItem";
import { useHistory ,useLocation } from 'react-router-dom';

function Navigation() {
  const location = useLocation()

  return (
    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
       {location.pathname.indexOf('fr') === -1 &&
        <>
        <NavigationItem key="context" menuItem={ {
          id: "context",
          href: "/",
          name: "Context",
        }} />
        <NavigationItem key="declaration" menuItem={ {
          id: "declaration",
          href: "/declaration",
          name: "The Brussels Declaration",
        }} />
        <NavigationItem key="signatory" menuItem={ {
          id: "signatory",
          href: "/signatory",
          name: "Signatory parties",
        }} />
        </>
      }
             {location.pathname.indexOf('fr') > -1 &&
        <>
        <NavigationItem key="context" menuItem={ {
          id: "contextFr",
          href: "/fr",
          name: "Contexte",
        }} />
        <NavigationItem key="declaration" menuItem={ {
          id: "declarationFr",
          href: "/fr/declaration",
          name: "La Déclaration de Bruxelles",
        }} />
        <NavigationItem key="signatory" menuItem={ {
          id: "signatoryFr",
          href: "/fr/signatory",
          name: "Signataires",
        }} />
        </>
      }
    </ul>
  );
}

export default Navigation;
