import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "components/Footer/Footer";
import Page404 from "containers/Page404/Page404";
import PageArchive from "containers/PageArchive/PageArchive";
import PageAuthor from "containers/PageAuthor/PageAuthor";
import PageSearch from "containers/PageSearch/PageSearch";
import PageSingle from "containers/PageSingle/PageSingle";
import PageSingleHasSidebar from "containers/PageSingle/PageSingleHasSidebar";
import PageSingleTemplate2 from "containers/PageSingle/PageSingleTemp2";
import PageSingleTemp2Sidebar from "containers/PageSingle/PageSingleTemp2Sidebar";
import PageSingleTemplate3 from "containers/PageSingle/PageSingleTemp3";
import PageSingleTemp3Sidebar from "containers/PageSingle/PageSingleTemp3Sidebar";
import PageAbout from "containers/PageAbout/PageAbout";
import PageContact from "containers/PageContact/PageContact";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageForgotPass from "containers/PageForgotPass/PageForgotPass";
import PageDashboard from "containers/PageDashboard/PageDashboard";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import HeaderContainer from "containers/HeaderContainer/HeaderContainer";
import PageHome from "containers/PageHome/PageHome";
import PageHomeDemo2 from "containers/PageHome/PageHomeDemo2";
import PageHomeDemo3 from "containers/PageHome/PageHomeDemo3";
import PageAuthorV2 from "containers/PageAuthor/PageAuthorV2";
import PageHomeDemo4 from "containers/PageHome/PageHomeDemo4";
import PageSearchV2 from "containers/PageSearch/PageSearchV2";
import MediaRunningContainer from "containers/MediaRunningContainer/MediaRunningContainer";
import PageSingleGallery from "containers/PageSingleGallery/PageSingleGallery";
import PageSingleAudio from "containers/PageSingleAudio/PageSingleAudio";
import PageSingleVideo from "containers/PageSingleVideo/PageSingleVideo";
import PageArchiveVideo from "containers/PageArchive/PageArchiveVideo";
import PageArchiveAudio from "containers/PageArchive/PageArchiveAudio";

import Login from "containers/PageLogin/PageLogin";
import PageSignatory from "containers/PageSignatory/PageSignatory";

import Homepage from "containers/PageRtbf/PageHomepage";
import Declaration from "containers/PageRtbf/PageDeclaration";
import Signatory from "containers/PageRtbf/PageSignatory";

import HomepageFr from "containers/PageRtbf/PageHomepageFr";
import DeclarationFr from "containers/PageRtbf/PageDeclarationFr";
import SignatoryFr from "containers/PageRtbf/PageSignatoryFr";


export const pages: Page[] = [
  { path: "/", exact: true, component: Homepage },
  
  { path: "/#", exact: true, component: Homepage },
  { path: "/fr/#", exact: true, component: HomepageFr },
  { path: "/fr", exact: true, component: HomepageFr },
  { path: "/declaration", component: Declaration },
  { path: "/fr/declaration", component: DeclarationFr },
  { path: "/signatory", component: Signatory },
  { path: "/fr/signatory", component: SignatoryFr },
  { path: "/login", component: Login },
  { path: "/admin", component: PageSignatory },
];

const Routes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <HeaderContainer />
      <Switch>
        {pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>
      {/* <Footer /> */}
      {/* MEDIA */}
      <MediaRunningContainer />
    </BrowserRouter>
  );
};

export default Routes;
