import React, { useEffect,useState } from "react";
import axios from 'axios';
import { Helmet } from "react-helmet";
import SectionHeroRtbf from "./SectionHeroRtbf";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import NcImage from "components/NcImage/NcImage";
import background_1 from "images/background_1.png"
import background_2 from "images/background_2.png"
import featuredImage from "images/header_2.png"
import Card5 from "components/Card5/Card5";
import { Link } from "react-router-dom";
import { format } from 'date-fns';


const PageSignatoryFr: React.FC = () => {
  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-astronomy theme-blue-coolGrey";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);


  const [parties, setParties] = useState([]);

  useEffect(() => {
    axios.get(`https://backend.develop.rtbf.flykube.agifly.cloud/api/v1/signatory`).then(response => {
        setParties(response.data.data)
    }).catch(err =>{
      console.log("error",err)
    })
  }, []);



  return (
    <div className="nc-PageHomepage overflow-hidden relative">
      <Helmet>
        <title>LA DECLARATION DE BRUXELLES</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <div className={`absolute inset-x-0 top-0 h-200 w-25 pl-10 py-32 mt-90 flex flex-col overflow-hidden z-0`}>
        <div className={`nc-NcImage absolute inset-0  overflow-hidden`} data-nc-id="NcImage">
          <img src={background_1} className="object-cover opacity-50" />
        </div>
      </div>
      <div className={`absolute inset-r-0 top-0 h-200 right-0 w-25 pl-10 py-32 mt-180 flex flex-col overflow-hidden z-0`}>
        <div className={`nc-NcImage absolute inset-0 right-0  overflow-hidden`} data-nc-id="NcImage">
          <img src={background_2} className="object-cover opacity-50" />
        </div>
      </div>
      {/* ======= START CONTAINER ============= */}

      <div className={`nc-SectionHeroRtbf mt-8 mr-8 ml-8`}>
         <div className="aspect-h-5 aspect-w-5 sm:aspect-w-8 lg:aspect-w-14">
        <NcImage
          containerClassName="absolute inset-0 rounded-[40px] overflow-hidden"
          src={featuredImage}
        />
        <span className="absolute inset-0 rounded-[40px] bg-black bg-opacity-0"></span>
        <div className="absolute inset-0 ">
          <div className="max-w titleDe">
            <h1 className="text-2xl sm:text-4xl md:text-6xl lg:text-6xl align-middle text-center font-bold text-white">
              <span className="line-clamp-2"> Signataires</span>
            </h1>          
          </div>
        </div>
      </div>
    </div>
    <div className="relative">
    <div className="container relative padding-0 md:pt-12 sm:pt-2 lg:pt-28 mb-10">

    <div className="lg:px-14 xl:px-20 2xl:px-28 grid sm:grid-cols-2 md:grid-cols-2 gap-4 lg:gap-8 transform mt-6 md:-mt-20">
    {parties && parties.map((item:any) => (
      <div
      className={`nc-Card5 relative p-6 group bg-neutral-100 shadow-xl rounded-3xl w-80p`}
      data-nc-id="Card5"
      data-nc-post-id={`id`}
      >
        <div className="flex flex-col">
          {/* <img src={`https://backend.develop.rtbf.flykube.agifly.cloud/filestore/`+item.logo} className="absolute inset-0 rounded-lg object-cover h-90p  max-w-20 rounded-3xl mt-2 ml-2 transform transition-transform group-hover:scale-105 duration-300 nc-will-change-transform" /> */}
          <NcImage
                          containerClassName="inset-0 h-90p  max-w-20 mt-2 ml-2  absolute rounded-3xl overflow-hidden"
                          src={`https://backend.develop.rtbf.flykube.agifly.cloud/filestore/`+item.logo}
                        />

          <h1
            className="block text-base font-semibold ml-25 text-neutral-800 dark:text-neutral-300 mt-1"
            title={`title`}
          >
            {item.fullname}
          </h1>
          <h2
            className="block text-base text-neutral-800 ml-25 dark:text-neutral-300 mb-6"
            title={`title`}
          >
            {item.titleFr ? item.titleFr : item.title}
          </h2>
          <span className="text-grey text-right absolute right-0 bottom-0 mr-4 mb-2"> {item.organisation}</span>
        </div>
      </div>
    ))
    }
   








    </div>
    </div>
    </div>

  
    </div>
  );
};

export default PageSignatoryFr;
