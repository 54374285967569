import React from "react";
import { useHistory ,useLocation } from 'react-router-dom';

import ButtonClose from "components/ButtonClose/ButtonClose";
import Logo from "components/Logo/Logo";
import { Disclosure } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import { NavItemType } from "./NavigationItem";
import DarkModeContainer from "containers/DarkModeContainer/DarkModeContainer";
import { NAVIGATION_DEMO } from "data/navigation";
import ButtonPrimary from "components/Button/ButtonPrimary";
import ButtonSecondary from "components/Button/ButtonSecondary";
import SocialsList from "components/SocialsList/SocialsList";
import { ChevronDownIcon } from "@heroicons/react/solid";

export interface NavMobileProps {
  onClickClose?: () => void;
}

const NavMobile: React.FC<NavMobileProps> = ({
  onClickClose,
}) => {

  const location = useLocation()

  return (
    <div className="overflow-y-auto max-w-sm h-screen py-2 transition transform shadow-lg ring-1  bg-white divide-y-2 divide-neutral-100 dark:divide-neutral-800">
      <div className="py-6 px-5">
        <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>
      </div>
      <ul className="flex flex-col py-6 px-2 space-y-1">
      <Disclosure
        key="context"
        as="li"
        className="text-neutral-900 dark:text-white"
      >
               {location.pathname.indexOf('fr') === -1 &&
        <>
        <NavLink
          exact
          strict
          className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
          to={{pathname:`/`}}
          activeClassName="text-secondary"
        >
          <span
            className={"block w-full"}
            onClick={onClickClose}
          >
            Context
          </span>
        </NavLink>
   
        <NavLink
          exact
          strict
          className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
          to={{pathname:`/declaration`}}
          activeClassName="text-secondary"
        >
          <span
            className={"block w-full"}
            onClick={onClickClose}
          >
            The Brussels declaration
          </span>
        </NavLink>
        <NavLink
          exact
          strict
          className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
          to={{pathname:`/signatory`}}
          activeClassName="text-secondary"
        >
          <span
            className={"block w-full"}
            onClick={onClickClose}
          >
            Signatory parties
          </span>
        </NavLink>
        </>
      }
                  {location.pathname.indexOf('fr') > -1 &&
        <>
        <NavLink
          exact
          strict
          className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
          to={{pathname:`/fr`}}
          activeClassName="text-secondary"
        >
          <span
            className={"block w-full"}
            onClick={onClickClose}
          >
            Contexte
          </span>
        </NavLink>
   
        <NavLink
          exact
          strict
          className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
          to={{pathname:`/fr/declaration`}}
          activeClassName="text-secondary"
        >
          <span
            className={"block w-full"}
            onClick={onClickClose}
          >
            La Déclaration de Bruxelles
          </span>
        </NavLink>
        <NavLink
          exact
          strict
          className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
          to={{pathname:`/fr/signatory`}}
          activeClassName="text-secondary"
        >
          <span
            className={"block w-full"}
            onClick={onClickClose}
          >
            Signataires
          </span>
        </NavLink>
        </>
      }
      </Disclosure>
      </ul>
      <div className="flex items-center justify-between py-6 px-5 space-x-4">
        <span >
        {location.pathname.indexOf('fr') === -1 &&
            <>
              <ButtonPrimary href={`${location.pathname}`} className="newPrimary">English</ButtonPrimary>
              <ButtonSecondary href={`/fr${location.pathname}`} className="newPrimary">Français</ButtonSecondary>
            </>
            }
            {location.pathname.indexOf('fr') > -1 &&
            <>
             <ButtonSecondary href={`${location.pathname.replace("/fr/", "/").replace("/fr", "/")}`} className="newPrimary">English</ButtonSecondary>
              <ButtonPrimary href={`${location.pathname}`} className="newPrimary">Français</ButtonPrimary>
             </>
            }
        </span>
      </div>
    </div>
  );
};

export default NavMobile;
